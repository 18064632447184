import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import { VBox } from 'Components/layout'

export const GameFilterGenre = ({ gameFilter, setGameFilter }) => {
  const handleChange = (event, filterName) => {
    setGameFilter({
      ...gameFilter,
      [filterName]: event.target.checked,
    })
  }

  return (
    <>
      <FormGroup>
        <h4>Genres</h4>
        <VBox>
          <FormControlLabel
            control={
              <Checkbox
                checked={gameFilter.genreVisualNovel}
                onChange={(event) => handleChange(event, 'genreVisualNovel')}
              />
            }
            label="Hide visual novel"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={gameFilter.genreIndie}
                onChange={(event) => handleChange(event, 'genreIndie')}
              />
            }
            label="Hide indie"
          />
        </VBox>
      </FormGroup>
    </>
  )
}
