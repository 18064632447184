import { Box, Button, CircularProgress, useMediaQuery } from '@mui/material'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { useTheme } from '@mui/material/styles'
import { ChildSpacerVAll } from 'Components/layout/ChildSpacer'
import { UserPredictionsList } from 'Components/panes/userPredictionsList'
import { UserWatchlist } from 'Components/panes/userWatchlist'
import * as React from 'react'
import { useMemo } from 'react'
import SwipeableViews from 'react-swipeable-views'
import { useGames } from 'store/game/gameContext'
import { useRounds } from 'store/game/roundContext'
import { useAuth } from 'store/user/authContext'
import { sortByProperty } from 'util/sort'
import { HBox } from './../../../Components/layout/HBox'
import { VBox } from './../../../Components/layout/VBox'
import { BadgeCounters } from './badgeCounters'
import { UserPicksSummary } from './userPicksSummary'
import { UserPanelViewHeader } from './userPanelViewHeader'
import { YourGamesList } from './yourGamesList'
import { query } from 'styles/mediaQueries'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

export const UserPanelView = ({
  user,
  gameList,
  gamesOutsideList,
  gameLimit,
  closeDrawer,
}) => {
  const { signOut } = useAuth()

  const isMobile = useMediaQuery(query.mobile)

  const { selectedRound, isGameReleasedInRound } = useRounds()

  const theme = useTheme()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = (index) => {
    setValue(index)
  }

  /// From UserPredictionsList.js. Maybe move to a custom hook?
  const {
    isLoadingUserGames,
    availableGames,
    watchedGames,
    isLoadingWatchedGames,
  } = useGames()

  const userWatchlist = useMemo(() => {
    let gamesMap = {}
    availableGames?.forEach((game) => {
      gamesMap[game.id] = game
    })

    return watchedGames
      .map((watchedGame) => {
        const game = gamesMap[watchedGame.id]

        if (!isGameReleasedInRound(game, selectedRound)) {
          return false
        }

        return {
          ...game,
          watched: watchedGame.watched,
        }
      })
      .filter((game) => game !== false)
      .sort((a, b) => sortByProperty(a, b, 'prediction', 'DESC'))
      .slice(0, 50)
  }, [availableGames, watchedGames, selectedRound, isGameReleasedInRound])

  const userPredictions = useMemo(() => {
    let gamesMap = {}
    availableGames?.forEach((game) => {
      gamesMap[game.id] = game
    })

    const predictions = user?.team?.predictions ?? []
    const predictionsKeys = Object.keys(predictions)
    return predictionsKeys
      .map((gameId) => {
        const game = gamesMap[gameId]

        if (!isGameReleasedInRound(game, selectedRound)) {
          return false
        }

        return {
          ...game,
          prediction:
            typeof predictions[gameId] === 'string'
              ? parseInt(predictions[gameId])
              : predictions[gameId],
        }
      })
      .filter((game) => game !== false)
      .sort((a, b) => sortByProperty(a, b, 'prediction', 'DESC'))
      .slice(0, 50)
  }, [user, availableGames, selectedRound, isGameReleasedInRound])
  /// end temp code

  return (
    <>
      <UserPanelViewHeader user={user} />
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="inherit"
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        <Tab label="Picks" {...a11yProps(0)} />
        <Tab label="Predictions" {...a11yProps(1)} />
        <Tab label="Watchlist" {...a11yProps(1)} />
      </Tabs>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <Box p={4} />
          <UserPicksSummary user={user} selectedRound={selectedRound} />
          <ChildSpacerVAll>
            {isLoadingUserGames && (
              <>
                <Box p={2} />
                <HBox align="center">
                  <VBox align="center">
                    <CircularProgress />
                    <Box p={1} />
                    Loading your game picks
                  </VBox>
                </HBox>
              </>
            )}
            {!isLoadingUserGames && (
              <>
                <BadgeCounters gameList={gameList} />
                <YourGamesList
                  gameList={gameList}
                  gamesOutsideList={gamesOutsideList}
                  gameLimit={gameLimit}
                  closeDrawer={closeDrawer}
                />
              </>
            )}
          </ChildSpacerVAll>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <Box p={1} />

          <UserPredictionsList
            userPredictions={userPredictions}
            isLoading={isLoadingUserGames}
          />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <Box p={1} />
          <UserWatchlist
            watchlist={userWatchlist}
            isLoading={isLoadingWatchedGames}
          />
        </TabPanel>
      </SwipeableViews>

      {isMobile && (
        <Button variant="outlined" style={{ width: '100%' }} onClick={signOut}>
          Sign out
        </Button>
      )}
    </>
  )
}
