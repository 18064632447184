import { PreviewDrawer } from 'Components/interactive/previewDrawer'
import { ChildSpacerVAll } from 'Components/layout/ChildSpacer'
import { DarkList } from 'Components/visual/darkList'
import { useNavigate } from 'react-router-dom'

export const HomeList = ({
  label,
  isLoading,
  list,
  columns,
  previewItemLimit,
  markFn,
}) => {
  const navigate = useNavigate()

  return (
    <>
      <ChildSpacerVAll>
        <PreviewDrawer
          anchor="left"
          headerButtonLabel="View all"
          ContentComponent={DarkList}
          previewItemLimit={previewItemLimit || 5}
          label={label}
          emptyText={`Currently no ${label.toLowerCase()} for this round.`}
          onRowClick={(rowData) => navigate(`/game/${rowData.slug}`)}
          list={list}
          isLoading={isLoading}
          columns={columns}
          markFn={markFn}
        />
      </ChildSpacerVAll>
    </>
  )
}
