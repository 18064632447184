import { Box, Collapse } from '@mui/material'
import { Alert, AlertTitle } from '@mui/material'
import { ExternalLink } from 'Components/interactive/ExternalLink'
import { colors } from 'styles/colors'

export const MaintenanceMessage = () => {
  const maintenanceMessage = {
    active: false,
    title:
      "We've made massive upgrades to our UI tech. There may be bugs creepin' about. 🐞",
    message:
      'Please report any issues on our Discord server linked below. Thanks! ❤️',
    /* message: `We're working on a fix asap! The rest of
  the site seems to be working so you can browse around. We'll be back to normal
  before you know it. Sorry for the inconvenience.`, */
  }

  if (!maintenanceMessage.active) {
    return null
  }

  return (
    <Collapse in={true}>
      <Alert severity="warning">
        <AlertTitle>{maintenanceMessage.title}</AlertTitle>
        {maintenanceMessage.message}
        <Box p={1} />
        <div>
          <span
            className="material-symbols-outlined"
            style={{ fontSize: 18, marginRight: 8, color: colors.theme }}
          >
            forum
          </span>
          <ExternalLink
            url="https://discord.gg/KrrCYAx9vZ"
            label="Critical Showdown Discord"
          />
        </div>
      </Alert>
    </Collapse>
  )
}
