import { Typography } from '@mui/material'
import { useRounds } from 'store/game/roundContext'
import { Bar } from './Bar'
import { Text } from 'Components/visual/text'

export const RoundTimeLine = () => {
  const { selectedRound, buildRoundProgressPercent } = useRounds()

  const roundProgressPercent = buildRoundProgressPercent(selectedRound)

  const startMonthName = selectedRound.startMonthName
  const endMonthName = selectedRound.endMonthName

  return (
    <div id="timeline" style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', gap: '8px', flexDirection: 'column' }}>
        <Typography component="h2" variant="h2" style={{ fontSize: '14px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Text>{startMonthName}</Text>
            <Text>{endMonthName}</Text>
          </div>
        </Typography>
        <Bar progress={roundProgressPercent} />
      </div>
    </div>
  )
}
