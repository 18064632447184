import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useRounds } from 'store/game/roundContext'
import { colors } from 'styles/colors'

export const RoundSwitchMobile = () => {
  const { selectedRound } = useRounds()
  const navigate = useNavigate()

  return (
    <div id="timeline" style={{ display: 'flex', flexDirection: 'column' }}>
      <Button
        onClick={() => navigate('/select-round')}
        style={{
          padding: '16px',
          margin: '0 0 8px 0',
          color: colors.white,
          display: 'flex',
          flex: 1,
          gap: '8px',
        }}
      >
        <span className="material-symbols-outlined">swap_horiz</span>{' '}
        <span style={{ fontSize: '18px' }}>{selectedRound.fullName}</span>
      </Button>
    </div>
  )
}
