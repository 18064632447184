import { ChildSpacerV } from 'Components/layout/ChildSpacer'
import { SubtleText } from 'Components/visual/SubtleText'

export const LeaderboardsUpdatedText = () => {
  const leaderboardsUpdateText = `Games, reviews and leaderboards are updated 3 times a day.`

  return (
    <ChildSpacerV>
      <SubtleText text={leaderboardsUpdateText} />
    </ChildSpacerV>
  )
}
