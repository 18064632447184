import { primitiveColors } from './primitiveColors'

export const semanticSurface = {
  /// from design system
  surfaceLayerLowest: primitiveColors.pColorGray950,
  surfaceLayerLow: primitiveColors.pColorGray900,
  surfaceLayerMedium: primitiveColors.pColorGray800,
  surfaceLayerHigh: primitiveColors.pColorGray700,

  surfaceThemeLow: primitiveColors.pColorTheme800,
  surfaceThemeMedium: primitiveColors.pColorTheme700,
  surfaceThemeHigh: primitiveColors.pColorTheme600,
}
