import { Box, CircularProgress } from '@mui/material'
import { ChildSpacerV } from 'Components/layout/ChildSpacer'
import { useAuth } from 'store/user/authContext'
import { ExternalSignupView } from './externalSignUpView'
import { SignInView } from './signInView'
import { UserPanelMini } from './userPanelMini'

export const ProfilePanelMiniView = () => {
  const { isUserLoading, user } = useAuth()

  return (
    <>
      <ChildSpacerV>
        {isUserLoading && (
          <Box alignItems="center" display="flex">
            <CircularProgress />{' '}
            <span style={{ marginLeft: 16 }}>Signing in...</span>
          </Box>
        )}
        {!isUserLoading && (
          <>
            {user.isSignedIn && !user.hasAccount && <ExternalSignupView />}
            {!user.isSignedIn && <SignInView />}
            {user.isAuthenticated && (
              <div style={{ paddingTop: 32 }}>
                <UserPanelMini />
              </div>
            )}
          </>
        )}
      </ChildSpacerV>
    </>
  )
}
