import { Box, Typography } from '@mui/material'
import { ChildSpacerVAll } from 'Components/layout/ChildSpacer'
import { HLine } from 'Components/visual/HLine'
import { TextSqueezer } from 'Components/visual/textWrapper'
import { colors } from 'styles/colors'

export const HowToPlayContent = () => {
  return (
    <>
      <h1>How to play</h1>
      <Box p={2} />
      <TextSqueezer>
        <ChildSpacerVAll>
          <div>
            <Typography variant="h4" style={{ color: colors.theme }}>
              Creating a profile
            </Typography>
            <Typography>
              Start by creating a profile by heading to "Create profile" if you
              haven't already. It's completely free while we're in beta. The
              profile is used to save your games and track your points.
            </Typography>
          </div>
          <div>
            <Typography variant="h4" style={{ color: colors.theme }}>
              Picking games
            </Typography>
            <Typography>
              Pick games from the games tab. You get points for the review
              scores metacritic assigns the games. You can't pick more than 10
              games per round. A round lasts a quarter of a year, for example
              from january to march. You can freely pick and drop games until
              the round is over if the games haven't received a review score.
              <br />
              <br />
              Currently only{' '}
              <strong>Xbox One/S/X, PS4/5 and Nintendo Switch</strong> games are
              included in the game. <br />
              <br />
              While games may show up as on PC they only will if they’re also on
              one of the mentioned consoles to keep the amount down. There are
              thousands of games released every week for PC which would make the
              game very hard to navigate without manual curation which we can't
              do currently while doing this in our spare time.
            </Typography>
          </div>
          <div>
            <Typography variant="h4" style={{ color: colors.theme }}>
              Keep checking back
            </Typography>

            <Typography>
              Game data on the site is updated every 4 hours so make sure to
              check back to see the latest release dates and scores for your
              collection. Make sure to switch out games that aren't likely to
              score, that you've lost faith in or that have been delayed to
              maximize your points. Anything can happen!
            </Typography>
          </div>
        </ChildSpacerVAll>
        <ChildSpacerVAll>
          <div>
            <Box p={1} />
            <HLine label="Winning a round"></HLine>
            <Box p={1} />
            <Typography>
              The profile with the most points at the end of a round wins! Wins
              are shared on equal points. Winners are announced on the site and
              by email when the next round starts.
            </Typography>
          </div>
        </ChildSpacerVAll>
      </TextSqueezer>
    </>
  )
}
