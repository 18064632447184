export const WordDotList = ({ list, subKey }) => {
  if (!list) {
    return null
  }

  return (
    <>
      {list &&
        list.length > 0 &&
        list
          .map((word, index) => (
            <div style={{ display: 'inline-block' }} key={index}>
              {index !== 0 && (
                <span
                  style={{
                    fontSize: '12px',
                    padding: '0 4px',
                    verticalAlign: 'baseline',
                  }}
                >
                  •
                </span>
              )}
              {subKey && word[subKey] ? word[subKey].name : word.name}
              {typeof word === 'string' ? word : ''}
            </div>
          ))
          .slice(0, 3)}
    </>
  )
}
