import { Box } from '@mui/material'
import {
  Link,
  ScrollRestoration,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { Breakpoint } from 'react-socks'
import { query } from 'styles/mediaQueries'
import { InspectTeamView } from '../sidebar/inspectTeamView'
import { useRounds } from 'store/game/roundContext'

export const TeamView = () => {
  const { teamUserId } = useParams()
  const { selectedRound } = useRounds()
  const navigate = useNavigate()

  return (
    <>
      <ScrollRestoration />
      <Breakpoint customQuery={query.mobileUp}>
        <>
          <Link
            to={'..'}
            onClick={(e) => {
              e.preventDefault()
              navigate(-1)
            }}
          >
            <span
              className="material-symbols-outlined"
              style={{ verticalAlign: 'text-top' }}
            >
              arrow_back
            </span>{' '}
            Back
          </Link>
          <Box p={1}></Box>
        </>
      </Breakpoint>
      <InspectTeamView userId={teamUserId} selectedRound={selectedRound} />
    </>
  )
}
