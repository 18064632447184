export const ExternalLink = ({ url, label }) => {
  return (
    <span>
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          fontSize: '14px',
          marginRight: 8,
        }}
      >
        <span style={{ verticalAlign: 'text-bottom' }}>{label}</span>
      </a>
      <span className="material-symbols-outlined" style={{ fontSize: '18px' }}>
        open_in_new
      </span>
    </span>
  )
}
