import * as React from 'react'
import { useState } from 'react'

const UINotifsContext = React.createContext()

export { UINotifsProvider, useUINotifs }

function UINotifsProvider(props) {
  const [gameActionSnackState, setGameActionSnackState] = useState({
    isOpen: false,
    text: '',
    severity: 'success', // success, info, warning, error
  })

  const uiNotifsContext = {
    gameActionSnackState,
    setGameActionSnackState,
  }

  return <UINotifsContext.Provider value={uiNotifsContext} {...props} />
}

function useUINotifs() {
  const context = React.useContext(UINotifsContext)
  if (!context) {
    throw new Error(`useUINotifs must be used within a UINotifsProvider`)
  }
  return context
}
