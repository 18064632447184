import { Box, Typography } from '@mui/material'
import { CSButton } from 'Components/game/CSButton'
import { IconButton } from 'Components/interactive'
import { HBox, VBox } from 'Components/layout'
import { ChildSpacerV } from 'Components/layout/ChildSpacer'
import { HowToPlayPanel } from 'Components/panes/howToPlayPanel'
import { LineText } from 'Components/visual'
import { useNavigate } from 'react-router-dom'
import { Breakpoint } from 'react-socks'
import { query } from 'styles/mediaQueries'
import { JoinIngress } from './joinIngress'

const joinButtonText = 'Play for free now!'

function JoinHeader() {
  return <Typography variant="h2">Pick the best reviewing games!</Typography>
}
export const JoinView = () => {
  const navigate = useNavigate()

  return (
    <VBox>
      <Breakpoint customQuery={query.mobile}>
        <ChildSpacerV>
          <div>
            <HowToPlayPanel gameLimitOverride={10} />
          </div>
          <IconButton
            label={joinButtonText}
            onClick={() => navigate('/sign-up')}
            size="large"
          />
          <Box p={2} />
          <LineText text="or" />
          <Box p={2} />
          <CSButton
            label="Sign in"
            onClick={() => {
              navigate('/user')
            }}
          ></CSButton>
          {/* <GoogleLoginButton /> */}
        </ChildSpacerV>
      </Breakpoint>
      {/*       <Breakpoint customQuery={query.mobileUp}>
        <Cols set="1fr 1fr" gap={32}>
          <ChildSpacerV>
            <JoinHeader />
            <JoinIngress />
          </ChildSpacerV>
          <ChildSpacerV>
            <div>
              <HowToPlayPanel gameLimitOverride={10} />
            </div>
            <IconButton
              label={joinButtonText}
              onClick={() => navigate('/sign-up')}
              size="large"
            />
          </ChildSpacerV>
        </Cols>
      </Breakpoint> */}
      <Breakpoint customQuery={query.mobileUp}>
        <ChildSpacerV>
          <JoinHeader />
          <JoinIngress />
          <div>
            <HowToPlayPanel gameLimitOverride={10} />
          </div>
          <IconButton
            icon="mail"
            label={joinButtonText}
            onClick={() => navigate('/sign-up')}
            size="large"
          />
          {/*           <LineText text="or" />
          <GoogleLoginButton /> */}
        </ChildSpacerV>
      </Breakpoint>
      <Box p={4} />
      <HBox align="center" style={{ verticalAlign: 'middle' }}>
        <span
          className="material-symbols-outlined"
          style={{ marginTop: '2px' }}
        >
          help
        </span>{' '}
        <a href="/how-to-play" style={{ marginLeft: '4px' }}>
          Learn more about how to play
        </a>
      </HBox>
      <Box p={5} />
    </VBox>
  )
}
