import { ChildSpacerVAll } from 'Components/layout/ChildSpacer'
import { HLine } from 'Components/visual/HLine'
import { useGameFilter } from 'store/game/gameFilterContext'
import { GameFilterReleaseStatus } from './gameFilterReleaseStatus'
import { GameFilterUnlikely } from './gameFilterUnlikely'
import { GameFilterCategory } from './gameFilterCategory'
import { GameFilterCompanies } from './gameFilterCompanies'
import { GameFilterName } from './gameFilterName'
import { GameFilterMedia } from './gameFilterMedia'
import { GameFilterScore } from './gameFilterScore'
import { GameFilterPlatforms } from './gameFilterPlatforms'
import { CSButton } from './CSButton'
import { useMediaQuery } from '@mui/material'
import { query } from 'styles/mediaQueries'
import { GameFilterGenre } from './gameFilterGenre'

export const GameFilter = () => {
  const { gameFilter, setGameFilter, clearGameFilter } = useGameFilter()

  const isMobile = useMediaQuery(query.mobile)

  return (
    <ChildSpacerVAll>
      <HLine>Filter</HLine>
      {!isMobile && <GameFilterName />}
      <GameFilterUnlikely
        gameFilter={gameFilter}
        setGameFilter={setGameFilter}
      />
      <GameFilterReleaseStatus
        orientation="vertical"
        gameFilter={gameFilter}
        setGameFilter={setGameFilter}
      />
      <GameFilterCategory
        gameFilter={gameFilter}
        setGameFilter={setGameFilter}
      />
      <GameFilterScore gameFilter={gameFilter} setGameFilter={setGameFilter} />
      <GameFilterPlatforms
        gameFilter={gameFilter}
        setGameFilter={setGameFilter}
      />
      <GameFilterCompanies
        gameFilter={gameFilter}
        setGameFilter={setGameFilter}
      />
      <GameFilterMedia gameFilter={gameFilter} setGameFilter={setGameFilter} />
      <GameFilterGenre gameFilter={gameFilter} setGameFilter={setGameFilter} />
      <CSButton onClick={() => clearGameFilter()} label="Reset all filters" />
    </ChildSpacerVAll>
  )
}
