import { Snackbar } from '@mui/material'
import { Alert } from '@mui/material'
import { useUINotifs } from 'store/game/uiNotificationsContext'

export const UINotifSnackbar = () => {
  const { gameActionSnackState, setGameActionSnackState } = useUINotifs()

  const handleCloseGameActionSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setGameActionSnackState({ isOpen: false, text: '', severity: 'success' })
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={gameActionSnackState.isOpen}
      autoHideDuration={5000}
      onClose={handleCloseGameActionSnack}
    >
      <Alert
        onClose={handleCloseGameActionSnack}
        severity={gameActionSnackState.severity}
      >
        {gameActionSnackState.text}
      </Alert>
    </Snackbar>
  )
}
