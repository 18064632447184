import { Box } from '@mui/material'

export function MultiplayerModes({ modes }) {
  if (!modes || modes?.length <= 0) return null

  const multiplayerModes = modes.map((mode) => {
    const activeModes = []
    if (mode.campaigncoop) activeModes.push('Campaign co-op')
    if (mode.dropin) activeModes.push('Drop-in')
    if (mode.lancoop) activeModes.push('LAN co-op')
    if (mode.offlinecoop) {
      let modeResult = 'Offline co-op'
      if (mode.offlinecoopmax) {
        modeResult += ` (${mode.offlinecoopmax} players)`
      }
      activeModes.push(modeResult)
    }

    if (mode.offlinemax)
      activeModes.push(`Offline (${mode.offlinemax} players)`)

    if (mode.onlinecoop) {
      let modeResult = 'Online co-op'
      if (mode.onlinecoopmax) {
        modeResult += ` (${mode.onlinecoopmax} players)`
      }
      activeModes.push(modeResult)
    }

    if (mode.onlinemax) activeModes.push(`Online (${mode.onlinemax} players)`)
    if (mode.splitscreen) activeModes.push('Split Screen')
    if (mode.splitscreenonline) activeModes.push('Split Screen Online')

    return { platform: mode?.platform?.abbreviation, features: activeModes }
  })

  return (
    <>
      <Box p={1} />
      {multiplayerModes?.map((mode, index) => (
        <div key={index}>
          <div>{mode.platform}</div>
          <ul style={{ margin: 0 }}>
            {mode?.features?.map((feature, modeIndex) => (
              <li key={modeIndex}>{feature}</li>
            ))}
          </ul>
        </div>
      ))}
    </>
  )
}
