import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export default function MobileNav() {
  const navigate = useNavigate()
  const location = useLocation()

  const [value, setValue] = useState()

  const convertUrlToLocationId = (pathname) => {
    const currentTopPath = pathname.split('/')[1]

    switch (currentTopPath) {
      case 'games':
        return 'games'
      case 'leaderboards':
        return 'leaderboards'
      case 'sign-in':
      case 'sign-up':
      case 'user':
        return 'user'
      case '':
        return 'home'
      default:
        return null
    }
  }

  const updateNavLocation = useCallback(() => {
    setValue(convertUrlToLocationId(location.pathname))
  }, [location.pathname])

  useEffect(() => {
    updateNavLocation()
  }, [updateNavLocation, location.pathname])

  const handleChange = (event, newValue) => {
    setValue(newValue)

    switch (newValue) {
      case 'games':
        navigate('/games')
        break
      case 'leaderboards':
        navigate('/leaderboards')
        break
      case 'user':
        navigate('/user')
        break
      case 'home':
        navigate('/')
        break
      default:
        break
    }
  }

  return (
    <>
      <BottomNavigation value={value} onChange={handleChange}>
        <BottomNavigationAction
          label="Home"
          value="home"
          icon={<span className="material-symbols-outlined">home</span>}
        />
        <BottomNavigationAction
          label="Games"
          value="games"
          icon={
            <span className="material-symbols-outlined">stadia_controller</span>
          }
        />
        <BottomNavigationAction
          label="Standings"
          value="leaderboards"
          icon={<span className="material-symbols-outlined">leaderboard</span>}
        />
        <BottomNavigationAction
          label="User"
          value="user"
          icon={<span className="material-symbols-outlined">person</span>}
        />
      </BottomNavigation>
    </>
  )
}
