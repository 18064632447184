import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'

export const GameFilterCompanies = ({ gameFilter, setGameFilter }) => {
  const handleChange = (event) => {
    setGameFilter({
      ...gameFilter,
      involvedCompanies: event.target.checked,
    })
  }

  return (
    <>
      <FormGroup>
        <h4>Involved companies</h4>
        <FormControlLabel
          control={
            <Checkbox
              checked={gameFilter.involvedCompanies}
              onChange={(event) => handleChange(event)}
            />
          }
          label="Has companies (developer, publisher...)"
        />
      </FormGroup>
    </>
  )
}
