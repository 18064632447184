import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'

export const GameFilterPlatforms = ({ gameFilter, setGameFilter }) => {
  const handleChange = (event, name) => {
    setGameFilter({
      ...gameFilter,
      platforms: {
        ...gameFilter.platforms,
        [name]: event.target.checked,
      },
    })
  }

  return (
    <>
      <FormGroup>
        <h4>Platforms</h4>
        <FormControlLabel
          control={
            <Checkbox
              checked={gameFilter.platforms.PS5}
              onChange={(event) => handleChange(event, 'PS5')}
            />
          }
          label="Playstation 5"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={gameFilter.platforms.PS4}
              onChange={(event) => handleChange(event, 'PS4')}
            />
          }
          label="Playstation 4"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={gameFilter.platforms.XSX}
              onChange={(event) => handleChange(event, 'XSX')}
            />
          }
          label="Xbox Series X"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={gameFilter.platforms.XONE}
              onChange={(event) => handleChange(event, 'XONE')}
            />
          }
          label="Xbox One"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={gameFilter.platforms.Switch}
              onChange={(event) => handleChange(event, 'Switch')}
            />
          }
          label="Nintendo Switch"
        />
      </FormGroup>
    </>
  )
}
