import { IconButton, Drawer as MUIDrawer, useMediaQuery } from '@mui/material'
import { query } from 'styles/mediaQueries'
import { spacing } from 'styles/spacing'

export const CSDrawer = ({
  anchor,
  ContentComponent,
  drawerOpen,
  setDrawerOpen,
  ...props
}) => {
  const closeDrawer = () => setDrawerOpen(false)

  const shouldUseMobilePadding = useMediaQuery(query.mobile)
  const currentPadding = shouldUseMobilePadding
    ? spacing.pageMobile
    : spacing.pageTablet

  const defaultPadding = {
    padding: `${currentPadding}`,
  }

  return (
    <MUIDrawer
      anchor={anchor ?? 'left'}
      variant="temporary"
      open={drawerOpen}
      onClose={closeDrawer}
      style={{
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'relative',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: anchor === 'left' ? 'flex-end' : 'flex-start',
          }}
        >
          <IconButton onClick={closeDrawer} variant="outlined" size="large">
            <span className="material-symbols-outlined">chevron_{anchor}</span>
          </IconButton>
        </div>
        <div style={defaultPadding}>
          <ContentComponent {...props} closeDrawer={closeDrawer} />
        </div>
      </div>
    </MUIDrawer>
  )
}
