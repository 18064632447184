// For Firebase JS SDK v7.20.0 and later, measurementId is optional
module.exports = {
  apiKey: "AIzaSyBPGNYEb02pLieWdNuUDJnH7l7JxO_o5iU",
  authDomain: "game-fantasy-league.firebaseapp.com",
  databaseURL: "https://game-fantasy-league.firebaseio.com",
  projectId: "game-fantasy-league",
  storageBucket: "game-fantasy-league.appspot.com",
  messagingSenderId: "147580215839",
  appId: "1:147580215839:web:69e11ec07482c27e60eb2a",
  measurementId: "G-W9WRK0VB7Q",
};
