import { HLine } from 'Components/visual/HLine'
import { colors } from 'styles/colors'
import { ExternalLink } from '../interactive/ExternalLink'
import { LeaderboardsUpdatedText } from './leaderboardsUpdatedText'

export const Contact = () => {
  return (
    <>
      <HLine icon="contact_support" label="Contact" />
      <div>
        <span
          className="material-symbols-outlined"
          style={{ fontSize: 18, marginRight: 8, color: colors.theme }}
        >
          forum
        </span>
        <ExternalLink
          url="https://discord.gg/KrrCYAx9vZ"
          label="Critical Showdown Discord"
        />
      </div>
      <div>
        <span
          className="material-symbols-outlined"
          style={{ fontSize: 18, marginRight: 8, color: colors.theme }}
        >
          chat
        </span>
        <ExternalLink
          url="https://x.com/critshowdown"
          label="@CritShowdown X/Twitter"
        />
      </div>
      <div>
        <span
          className="material-symbols-outlined"
          style={{ fontSize: 18, marginRight: 8, color: colors.theme }}
        >
          shopping_bag
        </span>
        <ExternalLink
          url="https://critical-showdown.myspreadshop.se"
          label="Critical Showdown Merch"
        />
      </div>
      <ExternalLink
        url="https://igdb.com"
        label="Games metadata powered by IGDB.com"
      />
      <LeaderboardsUpdatedText />
    </>
  )
}
