import { Button, Typography, useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useRounds } from 'store/game/roundContext'
import { colors } from 'styles/colors'
import { query } from 'styles/mediaQueries'
import { Bar } from './Bar'

export const RoundSwitch = () => {
  const { selectedRound, buildRoundProgressPercent } = useRounds()

  const roundProgressPercent = buildRoundProgressPercent(selectedRound)

  const navigate = useNavigate()

  const isMobile = useMediaQuery(query.mobile)

  const startMonthName = isMobile
    ? selectedRound.startMonthShortName
    : selectedRound.startMonthName
  const endMonthName = isMobile
    ? selectedRound.endMonthShortName
    : selectedRound.endMonthName

  return (
    <div id="timeline" style={{ display: 'flex', flexDirection: 'column' }}>
      <Button
        onClick={() => navigate('/select-round')}
        style={{
          padding: '16px',
          margin: '0 0 8px 0',
          color: colors.white,
          display: 'flex',
          flex: 1,
          gap: '8px',
        }}
      >
        <span className="material-symbols-outlined">swap_horiz</span>{' '}
        <span style={{ fontSize: '18px' }}>{selectedRound.fullName}</span>
      </Button>
      <div style={{ display: 'flex', gap: '8px', flexDirection: 'column' }}>
        <Typography component="h2" variant="h2" style={{ fontSize: '14px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>{startMonthName}</span>
            {endMonthName}
          </div>
        </Typography>
        <Bar progress={roundProgressPercent} />
      </div>
    </div>
  )
}
