import { HBox } from 'Components/layout'
import { ChildSpacerVAll } from 'Components/layout/ChildSpacer'
import { colors } from 'styles/colors'
import { VBox } from './../layout/VBox'

export const UserScore = ({ profileScore }) => {
  return (
    <ChildSpacerVAll>
      <VBox>
        <HBox align="center" style={{ fontSize: '18px' }}>
          {profileScore.score} points{' '}
          <span style={{ color: colors.theme, margin: '0 4px' }}> / </span> #
          {profileScore.position + 1}
        </HBox>
      </VBox>
    </ChildSpacerVAll>
  )
}
