import { ChildSpacerVAll } from 'Components/layout/ChildSpacer'
import { useGames } from 'store/game/gameContext'
import { useRounds } from 'store/game/roundContext'
import { useAuth } from 'store/user/authContext'
import { BadgeCounters } from '../profilePanel/badgeCounters'
import { UserPicksSummary } from '../profilePanel/userPicksSummary'
import { UserPanelViewHeader } from '../profilePanel/userPanelViewHeader'
import { UserReadonlyGamesList } from '../profilePanel/userReadonlyGamesList'

export const UserPanelReadonlyView = ({
  inspectedUser,
  inspectedUserSelectedGames,
  selectedRound,
  gameLimit,
}) => {
  const { user } = useAuth()
  const { selectedGames } = useGames()
  const { mainRounds } = useRounds()
  const currentRound = mainRounds.current
  const isCurrentRound = selectedRound.id === currentRound.id

  let loggedInUserGameList = []

  if (user.isAuthenticated && user.id !== inspectedUser.id) {
    if (isCurrentRound) {
      loggedInUserGameList = selectedGames
    } else {
      loggedInUserGameList = user?.team?.rounds[selectedRound.id] ?? []
    }
  } else {
    loggedInUserGameList = []
  }

  return (
    <div>
      <UserPanelViewHeader user={inspectedUser} />
      <ChildSpacerVAll>
        <UserPicksSummary user={inspectedUser} selectedRound={selectedRound} />
        <ChildSpacerVAll>
          <BadgeCounters gameList={inspectedUserSelectedGames} />
        </ChildSpacerVAll>
        <UserReadonlyGamesList
          selectedGames={inspectedUserSelectedGames}
          user={user}
          userGames={loggedInUserGameList}
          gameLimit={gameLimit}
        />
        {user.isAuthenticated &&
          loggedInUserGameList.length > 0 &&
          inspectedUserSelectedGames > 0 &&
          'Marked games are those in common with you.'}
      </ChildSpacerVAll>
    </div>
  )
}
