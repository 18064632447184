import { PreviewDrawer } from 'Components/interactive/previewDrawer'
import { WhatsNewList } from './whatsNewList'

export const WhatsNew = () => {
  return (
    <>
      <PreviewDrawer
        anchor="left"
        previewItemLimit={1}
        ContentComponent={WhatsNewList}
      />
    </>
  )
}
