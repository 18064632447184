import { Button } from '@mui/material'
import { ChildSpacerH, ChildSpacerV } from 'Components/layout/ChildSpacer'
import { HeroMessage } from 'Components/panes/heroMessage'
import { addDays } from 'date-fns'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Breakpoint } from 'react-socks'
import { useRounds } from 'store/game/roundContext'
import { useLeaderboards } from 'store/leaderboard/leaderboardContext'
import { query } from 'styles/mediaQueries'
import { buildTodayZero, isWithinDaysAfter } from 'util/date'
import { sortByProperty } from 'util/sort'
import { TextSqueezer } from './textWrapper'

export const WinnerMessage = () => {
  const navigate = useNavigate()

  const { mainRounds } = useRounds()
  const currentRound = mainRounds.current
  const previousRound = mainRounds.prev

  const { leaderboards, selectedLeaderboardType } = useLeaderboards()

  const leaderboardsListData = useMemo(() => {
    const prevLeaderboards = leaderboards[previousRound.id]
      ? leaderboards[previousRound.id][selectedLeaderboardType]
      : []
    return prevLeaderboards?.sort((a, b) =>
      sortByProperty(a, b, 'score', 'DESC')
    )
  }, [leaderboards, previousRound, selectedLeaderboardType])

  if (
    !isWithinDaysAfter(
      addDays(currentRound.startDate, 6),
      buildTodayZero(),
      4
    ) ||
    !leaderboardsListData ||
    leaderboardsListData.length < 2
  ) {
    return null
  }

  const winner = leaderboardsListData[0]
  const runnerUp = leaderboardsListData[1]

  const sharedVictory = winner.score === runnerUp.score

  return (
    <HeroMessage>
      <ChildSpacerV>
        <h2>{previousRound.roundName} has been decided!</h2>
      </ChildSpacerV>
      <TextSqueezer>
        {sharedVictory && (
          <p>
            Ladies and gentlemen! In a rare feat <strong>{winner.name}</strong>{' '}
            and <strong>{runnerUp.name}</strong> shares the victory for{' '}
            {previousRound.roundName} at exactly {winner.score} points!
          </p>
        )}
        {!sharedVictory && (
          <p>
            Congratulations to <strong>{winner.name}</strong> who took{' '}
            {previousRound.roundName} with {winner.score} points{' '}
            {/* (new record!) */} leading by just{' '}
            {winner.score - runnerUp.score} points before runner-up{' '}
            {runnerUp.name} {/* (their best position yet!) */}.
          </p>
        )}

        <p>
          Thanks to everyone who played this round of Critical Showdown. We'll
          continue to update and evolve the game during this quarter.
        </p>
        <p>
          Time to pick some games for {currentRound.roundName}. The new round
          has already begun!
        </p>
      </TextSqueezer>
      <Breakpoint customQuery={query.mobile}>
        <ChildSpacerV>
          <Button variant="outlined" onClick={() => navigate('/games')}>
            Pick some games!
          </Button>
          <Button variant="outlined" onClick={() => navigate('/leaderboards')}>
            View leaderboard
          </Button>
        </ChildSpacerV>
      </Breakpoint>
      <Breakpoint customQuery={query.mobileUp}>
        <ChildSpacerH>
          <Button variant="outlined" onClick={() => navigate('/games')}>
            Pick some games!
          </Button>
          <Button variant="outlined" onClick={() => navigate('/leaderboards')}>
            View leaderboard
          </Button>
        </ChildSpacerH>
      </Breakpoint>
    </HeroMessage>
  )
}
