import { Typography } from '@mui/material'
import { ChildSpacerVAll } from 'Components/layout/ChildSpacer'
import { CSTooltip } from 'Components/visual/csTooltip'
import { spacing } from 'styles/spacing'
import Crown from 'Components/visual/crown.svg'

function ProfileHeaderWinnerCrown({ entry }) {
  if (!entry.wins || entry.wins <= 0) {
    return null
  }

  return (
    <CSTooltip id={'profile-crown'} text={`${entry.wins}-time winner`}>
      <img
        src={Crown}
        alt="crown"
        style={{
          width: 24,
          height: 24,
          transform: 'rotateZ(20deg)',
          position: 'absolute',
          color: 'gold',
          right: 0,
        }}
      />
    </CSTooltip>
  )
}

export const UserPanelViewHeaderMini = ({ user }) => {
  return (
    <ChildSpacerVAll>
      <Typography
        variant="h3"
        style={{
          textAlign: 'center',
          position: 'relative',
          gap: spacing.md,
          display: 'flex',
        }}
      >
        {user.team.name} <ProfileHeaderWinnerCrown entry={user.team} />
      </Typography>
    </ChildSpacerVAll>
  )
}
