import { buildIgdbImageLink, buildResizeLink } from 'util/image'

export const GameCardImage = ({ game }) => {
  let imageSrc = null

  if (game.screenshots && game.screenshots.length > 0) {
    imageSrc = buildIgdbImageLink(
      game.screenshots[0].image_id,
      'screenshot_med_2x'
    )
  } else if (game.cover) {
    imageSrc = buildIgdbImageLink(game.cover.image_id, 'screenshot_med_2x')
  } else if (game.background_image) {
    imageSrc = buildResizeLink(game.background_image, '640')
  }

  /*
  /games
  /resize/1280/-/screenshots

  https://media.rawg.io/media/screenshots/364/36414526a440e61da80716ae1ec11136.jpg

  You may resize pics. Example: this pic → https://media.rawg.io/media/screenshots/831/8314575622c6ac8de538e890ec6a2aab.jpg 
  Add these params to resize →
  https://media.rawg.io/media/resize/1920/-/screenshots/831/8314575622c6ac8de538e890ec6a2aab.jpg 
  https://media.rawg.io/media/resize/1280/-/screenshots/831/8314575622c6ac8de538e890ec6a2aab.jpg
  https://media.rawg.io/media/resize/640/-/screenshots/831/8314575622c6ac8de538e890ec6a2aab.jpg
  https://media.rawg.io/media/resize/200/-/screenshots/831/8314575622c6ac8de538e890ec6a2aab.jpg  
  */

  if (!imageSrc) {
    return null
  }

  return (
    <>
      <img
        alt="Poster"
        src={imageSrc}
        style={{
          position: 'absolute',
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      />
    </>
  )
}
