import { PickDropButton } from 'Components/game/pickDropButton'
import { ListButton } from 'Components/game/ListButton'
import { GameName } from 'Components/game/gameName'
import { ExternalLink } from 'Components/interactive/ExternalLink'
import { HBox, VBox } from 'Components/layout'
import { ChildSpacerH, ChildSpacerV } from 'Components/layout/ChildSpacer'
import { SubtleText } from 'Components/visual/SubtleText'
import { WordDotList } from 'Components/visual/WordDotList'
import { ScoreBadge } from 'Components/visual/scoreBadge/scoreBadge'
import { formatDistance } from 'date-fns'
import { buildTodayZero } from 'util/date'
import { buildIgdbImageLink } from '../../../util/image'
import { HLine } from '../../visual/HLine'
import { GameAdminControls } from './gameAdminControls'
import { GamePredictionsList } from './gamePredictionsList'
import { UserPredictGame } from './userPredictGame'
import { InvolvedCompanies } from './involvedCompanies'
import { MultiplayerModes } from './multiplayerModes'
import { ReadMore } from './readMore'
import { Platforms } from 'pages/home/games/platforms'
import { GameProfile } from 'Components/game/gameProfile'
import { WatchlistButton } from 'Components/game/watchlistButton'
import { RoundCardButton } from 'Components/game/RoundCardButton'
import { colors } from 'styles/colors'
import { Box } from '@mui/material'
import { Text } from 'Components/visual/text'

export const GameViewMobile = ({ selectedGame }) => {
  return (
    <>
      <ChildSpacerV>
        <GameAdminControls game={selectedGame} />
        <div style={{ width: '100%', maxWidth: '100%', overflow: 'auto' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {selectedGame.screenshots &&
              selectedGame.screenshots.length > 0 &&
              selectedGame.screenshots.map((shot, index) => {
                return (
                  <img
                    key={index}
                    src={buildIgdbImageLink(shot.image_id, 'screenshot_med_2x')}
                    alt="game"
                    style={{
                      width: '100%',
                      maxHeight: '320px',
                      marginRight: '10px',
                    }}
                  />
                )
              })}
          </div>
        </div>
        {selectedGame?.videos?.length > 0 && selectedGame?.videos[0] && (
          <div>
            <iframe
              title="Game Video"
              width="100%"
              height="300"
              src={`https://www.youtube.com/embed/${selectedGame.videos[0].video_id}?controls=0&autoplay=0&mute=1&loop=1&playlist=${selectedGame.videos[0].video_id}`}
              frameBorder="0"
            ></iframe>
          </div>
        )}
        <HBox align="center-right" style={{ marginBottom: '8px' }}>
          <VBox>
            <ChildSpacerH style={{ justifyContent: 'space-between' }}>
              <GameName game={selectedGame} />
            </ChildSpacerH>
            {selectedGame.category !== 'Full game' && (
              <SubtleText>{selectedGame.category}</SubtleText>
            )}
            <GameProfile game={selectedGame} showLabel={true} />
          </VBox>
          <ChildSpacerH>
            <WatchlistButton
              game={selectedGame}
              ButtonComponent={RoundCardButton}
            />
            <PickDropButton game={selectedGame} ButtonComponent={ListButton} />
          </ChildSpacerH>
        </HBox>
        {selectedGame.metacritic > 0 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              backgroundColor: colors.darkPurple,
              borderRadius: 12,
              padding: '16px 32px',
            }}
          >
            Final score
            <span>
              <ScoreBadge game={selectedGame} disablePrediction={true} />
              <Text>
                {selectedGame.aggregated_rating_count &&
                  selectedGame.aggregated_rating_count}{' '}
                {selectedGame.aggregated_rating_count === 1
                  ? 'source'
                  : 'sources'}
              </Text>
            </span>
          </div>
        )}{' '}
        <Box p={1}></Box>
        <HLine
          label={
            selectedGame.metacritic > 0 ? 'Closest predictions' : 'Predictions'
          }
        />
        <GamePredictionsList game={selectedGame} />
        <UserPredictGame game={selectedGame} />
        <Box p={1}></Box>
        <div>
          <h4>Release date</h4>
          <div>{selectedGame.released}</div>
        </div>
        <div>
          <h4>Platforms</h4>
          <Platforms platforms={selectedGame.platforms} />
        </div>
        <div>
          <InvolvedCompanies list={selectedGame.involved_companies} />
        </div>
        {selectedGame.summary && (
          <div>
            <h4>Summary</h4>
            <ReadMore id="summary" text={selectedGame.summary} />
          </div>
        )}
        {(selectedGame?.genres || selectedGame?.themes?.length > 0) && (
          <div>
            <h4>Genres & themes</h4>
            {selectedGame?.genres && (
              <div>
                <WordDotList list={selectedGame?.genres} />
              </div>
            )}
            {selectedGame?.themes?.length > 0 && (
              <div>
                <WordDotList list={selectedGame?.themes} />
              </div>
            )}
          </div>
        )}
        {(selectedGame.player_perspectives ||
          selectedGame.game_modes ||
          selectedGame.multiplayer_modes) && (
          <div>
            <h4>Gameplay</h4>
            <div>
              <WordDotList list={selectedGame.player_perspectives} />
            </div>
            <div>
              <WordDotList list={selectedGame.game_modes} />
            </div>
            <MultiplayerModes modes={selectedGame.multiplayer_modes} />
          </div>
        )}
        {selectedGame.storyline && (
          <div>
            <h4>Storyline</h4>
            <ReadMore id="summary" text={selectedGame.storyline} />
          </div>
        )}
        <Box p={3}></Box>
      </ChildSpacerV>
      <div>
        <ExternalLink
          url={`${selectedGame.url}`}
          label={`Read more on IGDB`}
          icon="link-external"
        />
        <SubtleText>
          Updated{' '}
          {formatDistance(buildTodayZero(), new Date(selectedGame.updated))} ago
        </SubtleText>
      </div>
      <Box p={3}></Box>
    </>
  )
}
