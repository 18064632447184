import { CSTooltip } from 'Components/visual/csTooltip'
import { useWatchGame } from 'store/game/watchlistContext'
import { useAuth } from 'store/user/authContext'
import { colors } from 'styles/colors'

export const WatchlistButton = ({
  game,
  ButtonComponent,
  watchLabel,
  unWatchLabel,
}) => {
  const { onWatchGame, onUnwatchGame, savingGameId } = useWatchGame()
  const { user } = useAuth()
  if (!user.isAuthenticated) {
    return null
  }

  const watched = !!game.watched

  const awaitingAction = savingGameId
  const saving = savingGameId === game.id

  return (
    <>
      <CSTooltip
        text={
          <>
            {!watched && (
              <>
                Add game to your watchlist. Useful to keep track of games you
                want to play or use on Critical Showdown. It is private and does
                not affect any leaderboards.
              </>
            )}
            {watched && <>Remove game from your watchlist</>}
          </>
        }
      >
        <div>
          {watched && (
            <ButtonComponent
              icon="heart_check"
              onClick={async (e) => {
                e.preventDefault()
                e.stopPropagation()
                await onUnwatchGame(game, user.team.id)
              }}
              saving={saving}
              disabled={awaitingAction}
              color={colors.theme}
              label={unWatchLabel}
            />
          )}
          {!watched && (
            <ButtonComponent
              icon="heart_plus"
              onClick={async (e) => {
                e.preventDefault()
                e.stopPropagation()
                await onWatchGame(game, user.team.id)
              }}
              saving={saving}
              disabled={awaitingAction}
              color={colors.theme}
              label={watchLabel}
            />
          )}
        </div>
      </CSTooltip>
    </>
  )
}
