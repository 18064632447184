export function findRealFromIds(gameList, findIds) {
  let results = []

  if (!findIds || findIds.length === 0) {
    //console.warn('Invalid id list:', findIds)
    return []
  }

  const idsToFind = [...findIds]

  for (const game of gameList) {
    for (let i = 0; i < idsToFind.length; i++) {
      const teamGameId = idsToFind[i]

      if (game.id === teamGameId) {
        results.push(game)
        idsToFind.splice(i, 1)
        break
      }
    }
    if (idsToFind.length === 0) {
      break
    }
  }

  if (idsToFind.length > 0) {
    console.warn('findRealFromIds: Could not find all games:', idsToFind)

    for (const gameId of idsToFind) {
      results.push({
        id: gameId,
        released: null,
        name: 'Unknown ' + gameId,
        metacritic: null,
        slug: 'unknown',
      })
    }
  }

  return results
}
