import { Button, CircularProgress } from '@mui/material'
export const CSButton = ({
  label,
  variant,
  icon,
  onClick,
  saving,
  disabled,
  style,
}) => {
  return (
    <Button
      disabled={!!disabled}
      variant={variant ?? 'outlined'}
      onClick={onClick}
      style={{
        ...style,
        position: 'relative',
      }}
    >
      {saving && (
        <span
          style={{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <CircularProgress size={16} style={{ marginRight: 4 }} />
        </span>
      )}
      {!saving && (
        <>
          <span
            className="material-symbols-outlined"
            style={{ marginRight: 4 }}
          >
            {icon}
          </span>
        </>
      )}{' '}
      <span style={{ visibility: saving ? 'hidden' : 'visible' }}>{label}</span>
    </Button>
  )
}
