import { Box, TextField } from '@mui/material'
import { ErrorSummary } from 'Components/form/errorSummary'
import { CSButton } from 'Components/game/CSButton'
import { ChildSpacerVAll } from 'Components/layout/ChildSpacer'
import { useState } from 'react'
import { apiEndpoints } from 'showdown-api/api'
import { apiPost } from 'showdown-api/rest'
import { useAuth } from 'store/user/authContext'
import { HBox } from '../../layout/HBox'
import { VBox } from 'Components/layout'

export const GameAdminControls = ({ game }) => {
  const { user } = useAuth()

  const [formState, setFormState] = useState({
    score: game.metacritic ?? '',
    errors: {},
    saving: false,
    overrideSaved: false,
  })

  if (!user.isAuthenticated || !user.admin) {
    return null
  }

  const handleChange = (event) => {
    const value = event.target.value
    setFormState({ ...formState, [event.target.name]: value })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setFormState({ ...formState, saving: true })

    const newGameData = {
      id: game.id,
      score: formState.score,
    }

    await apiPost(apiEndpoints.game_admin_save, newGameData)
      .then((data) => {
        setFormState({
          ...formState,
          overrideSaved: true,
          saving: false,
          errors: [],
        })
      })
      .catch((error) => {
        if (error) {
          setFormState({
            ...formState,
            errors: error.message ?? {},
            saving: false,
          })
        }
      })
  }

  const { errors, saving } = formState

  return (
    <ChildSpacerVAll>
      <div>
        <h4>Admin tools</h4>
        <VBox>
          <TextField
            variant="filled"
            color="primary"
            id="score"
            label="Override score"
            name="score"
            type="number"
            style={{ width: '100%' }}
            helperText={errors.score}
            error={errors.score ? true : false}
            onChange={handleChange}
            value={formState.score}
          />
          <CSButton
            label="Save"
            onClick={handleSubmit}
            saving={saving}
            disabled={saving || !formState.score}
          />
        </VBox>
      </div>
      {formState.overrideSaved && (
        <HBox>
          <div>
            Override saved. Changes are updated on the next full update of the
            API. Ask an admin to trigger a full update if needed.
          </div>
        </HBox>
      )}
      {errors?.length && (
        <ErrorSummary label="Could not save" errors={errors} />
      )}
      <Box p={1} />
    </ChildSpacerVAll>
  )
}
