import { ChildSpacerV } from 'Components/layout/ChildSpacer'
import { useState } from 'react'
import { CSDrawer } from './drawer'

export const PreviewDrawer = ({
  drawerButtonLabel,
  previewItemLimit,
  anchor,
  ContentComponent,
  ...props
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false)

  return (
    <>
      <CSDrawer
        anchor={anchor}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        ContentComponent={() => (
          <ChildSpacerV>
            <ContentComponent {...props} />
          </ChildSpacerV>
        )}
      />

      <ChildSpacerV>
        <ContentComponent
          limit={previewItemLimit}
          {...props}
          onButtonClick={() => setDrawerOpen(true)}
        />
      </ChildSpacerV>
    </>
  )
}
