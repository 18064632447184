import { Button } from '@mui/material'
import { ChildSpacerVAll } from 'Components/layout/ChildSpacer'
import { HeroMessage } from 'Components/panes/heroMessage'
import { useNavigate } from 'react-router-dom'
import { useRounds } from 'store/game/roundContext'
import { isWithinDaysAfter } from 'util/date'
import { buildTodayZero } from './../../util/date'
import { TextSqueezer } from './textWrapper'

export const EndRoundMessage = () => {
  const navigate = useNavigate()

  const { mainRounds } = useRounds()
  const currentRound = mainRounds.current
  const previousRound = mainRounds.prev

  if (!isWithinDaysAfter(currentRound.startDate, buildTodayZero(), 4)) {
    return null
  }

  return (
    <HeroMessage>
      <ChildSpacerVAll>
        <h2>
          Round {previousRound.roundName} has ended! The winner is announced
          shortly.
        </h2>
      </ChildSpacerVAll>
      <TextSqueezer>
        <p>
          As is tradition here at Critical Showdown we'll wait a week for all
          reviews to stabilize before announcing a winner.
        </p>
        <p>Thanks everyone who played this round of Critical Showdown!</p>
        <p>
          Time to pick some games for {currentRound.roundName}. The new round
          has already begun - Good luck!
        </p>
      </TextSqueezer>
      <Button
        variant="outlined"
        onClick={() => navigate('/games')}
        style={{ marginRight: '10px' }}
      >
        Pick some games!
      </Button>
    </HeroMessage>
  )
}
