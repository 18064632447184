import { Box, Button, CircularProgress } from '@mui/material'
import { ChildSpacerV } from 'Components/layout/ChildSpacer'
import { SubtleText } from 'Components/visual/SubtleText'
import { useNavigate } from 'react-router-dom'
import { useGames } from 'store/game/gameContext'
import { useRounds } from 'store/game/roundContext'
import { semanticSurface } from 'styles/semanticSurface'
import shape from 'styles/shape'
import { spacing } from 'styles/spacing'
import { HBox } from '../../../Components/layout/HBox'
import { VBox } from '../../../Components/layout/VBox'
import { BadgeCounters } from './badgeCounters'
import { UserPicksSummary } from './userPicksSummary'
import { UserPanelViewHeaderMini } from './userPanelViewHeaderMini'
import { UserPredictionsSummary } from './userPredictionsSummary'
import { usePredictGame } from 'store/game/userPredictGameContext'
import { useAuth } from 'store/user/authContext'

export const UserPanelMiniView = ({ user, gameList }) => {
  const { selectedRound } = useRounds()
  const { isLoadingUserGames } = useGames()
  const { gamesUserPredicted } = usePredictGame()
  const { signOut } = useAuth()

  const navigate = useNavigate()

  return (
    <div
      style={{
        backgroundColor: semanticSurface.surfaceLayerLow,
        borderRadius: shape.borderRadiusMedium,
        padding: spacing.sm,
      }}
    >
      <UserPanelViewHeaderMini user={user} />

      <ChildSpacerV>
        <div
          style={{
            backgroundColor: semanticSurface.surfaceLayerMedium,
            padding: spacing.sm,
            borderRadius: shape.borderRadiusSmall,
          }}
        >
          <h4>Picks</h4>
          <UserPicksSummary user={user} selectedRound={selectedRound} />
          {isLoadingUserGames && (
            <>
              <Box p={2} />
              <HBox align="center">
                <VBox align="center">
                  <CircularProgress />
                  <Box p={1} />
                  Loading your game picks
                </VBox>
              </HBox>
            </>
          )}
          {!isLoadingUserGames && (
            <>
              <BadgeCounters gameList={gameList} />
            </>
          )}
        </div>
        <div
          style={{
            backgroundColor: semanticSurface.surfaceLayerMedium,
            padding: spacing.xs,
            borderRadius: shape.borderRadiusSmall,
          }}
        >
          <h4>Predictions</h4>
          <UserPredictionsSummary user={user} selectedRound={selectedRound} />
          {/* maybe show how many hits per offbyX here instead of just how many predictions and preferably if it is a new record */}
          <HBox align="center">
            <SubtleText>
              {gamesUserPredicted.length} predictions made
            </SubtleText>
          </HBox>
        </div>
        <Button
          variant="outlined"
          onClick={() => {
            navigate('/team-manage')
          }}
        >
          My profile
        </Button>

        <Box p={2} />
        <Button variant="text" style={{ width: '100%' }} onClick={signOut}>
          Sign out
        </Button>
      </ChildSpacerV>
    </div>
  )
}
