import { analyticsLogEvent, signOutFromFirebase } from 'Components/firebase'
import React, { useState } from 'react'
import { apiEndpoints } from 'showdown-api/api'
import { apiPost } from 'showdown-api/rest'
import { sortByReleasedAndName } from 'util/sort'
import { useGames } from './gameContext'
import { useGameFilter } from './gameFilterContext'
import { useRounds } from './roundContext'
import { useUINotifs } from './uiNotificationsContext'

const PickGameContext = React.createContext()

function PickGameProvider({ ...props }) {
  const { children } = props
  const { filteredGames, setFilteredGames } = useGameFilter()

  const [savingGameId, setSavingGameId] = useState(null)

  const { setGameActionSnackState } = useUINotifs()

  const {
    selectedGames,
    setSelectedGames,
    selectedGamesOutsideRound,
    setSelectedGamesOutsideRound,
  } = useGames()

  const { mainRounds, selectedRound, isInCurrentRound } = useRounds()

  const onPickGame = async (game, userTeamId) => {
    try {
      console.log('Adding game')
      analyticsLogEvent('manage_games', { name: 'add_game' })
      setSavingGameId(game.id)
      await apiPost(apiEndpoints.team_fantasy_add, {
        gameId: game.id,
        teamId: userTeamId,
      }) // errors are thrown and caught below
      setSavingGameId(null)
      analyticsLogEvent('manage_games', { name: 'confirm_team_change' })

      // Filter out game to be selected
      const filteredGamesWOGame = filteredGames.filter(
        (filteredGame) => filteredGame.id !== game.id
      )

      // Update selected games
      const updatedSelectedGames = [
        ...selectedGames,
        { id: game.id, selected: { id: game.id, selected: true } },
      ]
      setSelectedGames(updatedSelectedGames)

      // Readd updated selected game to filtered games list
      const fullGame = { ...game, selected: { id: game.id, selected: true } }
      setFilteredGames(
        [...filteredGamesWOGame, fullGame].sort(sortByReleasedAndName)
      )

      setGameActionSnackState({
        isOpen: true,
        text: `${game.name} added to your ${mainRounds.current.fullName} collection`,
        severity: 'success',
      })

      return true
    } catch (error) {
      setSavingGameId(null)
      console.error('Add game error: ', error.message)
      if (error.status === 403) {
        console.log('Not logged in')
        setGameActionSnackState({
          isOpen: true,
          text: `You need to log in first`,
          severity: 'info',
        })
        signOutFromFirebase()
      } else {
        setGameActionSnackState({
          isOpen: true,
          text: `Could not add ${game.name}`,
          severity: 'error',
        })
      }

      return false
    }
  }

  const onDropGame = async (game, userTeamId) => {
    try {
      console.log('Dropping game')
      analyticsLogEvent('manage_games', { name: 'drop_game' })
      setSavingGameId(game.id)

      await apiPost(apiEndpoints.team_fantasy_drop, {
        gameId: game.id,
        teamId: userTeamId,
      })
      setSavingGameId(null)
      analyticsLogEvent('manage_games', { name: 'confirm_team_change' })

      // Filter out deselected game from round game list
      const filteredGamesWOGame = filteredGames.filter(
        (filteredGame) => filteredGame.id !== game.id
      )

      if (
        isInCurrentRound(game) ||
        selectedRound.id !== mainRounds.current.id
      ) {
        // Remove game from selected games
        const updatedSelectedGames = selectedGames.filter(
          (selectedGame) => selectedGame.id !== game.id
        )
        setSelectedGames(updatedSelectedGames)
      } else {
        const updatedSelectedGamesOutsideRound =
          selectedGamesOutsideRound.filter(
            (selectedGame) => selectedGame.id !== game.id
          )
        setSelectedGamesOutsideRound(updatedSelectedGamesOutsideRound)
      }

      // Read deselected game to round game list
      const fullGame = { ...game, selected: { id: game.id, selected: false } }
      setFilteredGames(
        [...filteredGamesWOGame, fullGame].sort(sortByReleasedAndName)
      )

      setGameActionSnackState({
        isOpen: true,
        text: `${game.name} dropped from your ${mainRounds.current.fullName} collection`,
        severity: 'success',
      })

      return true
    } catch (error) {
      setSavingGameId(null)
      console.error('Drop game error: ', error.message)
      if (error.status === 403) {
        console.log('Not logged in')
        setGameActionSnackState({
          isOpen: true,
          text: `You need to log in first`,
          severity: 'info',
        })
        signOutFromFirebase()
      } else {
        setGameActionSnackState({
          isOpen: true,
          text: `Could not drop ${game.name}.`,
          severity: 'error',
        })
      }

      return false
    }
  }

  const pickGameContext = {
    onPickGame,
    onDropGame,
    savingGameId,
  }

  return (
    <PickGameContext.Provider value={pickGameContext}>
      {children}
    </PickGameContext.Provider>
  )
}

function usePickGame() {
  const context = React.useContext(PickGameContext)
  if (!context) {
    throw new Error(`usePickGame must be used within a PickGameProvider`)
  }
  return context
}

export { PickGameProvider, usePickGame }
