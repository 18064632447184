export const primitiveOpacity = {
  /// from design system
  /// transparency, primitives to apply to main colors.
  pOpacity4: '0.04',
  pOpacity8: '0.08',
  pOpacity16: '0.16',
  pOpacity24: '0.24',
  pOpacity48: '0.48',
  pOpacity64: '0.64',
  pOpacity80: '0.80',
}
