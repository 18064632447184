import { HomePage } from 'pages/home/home'
import { useAuth } from 'store/user/authContext'

export const AdminPage = () => {
  const { user } = useAuth()

  if (!user.isAuthenticated) {
    return <HomePage />
  }

  return (
    <div style={{ padding: '40px' }}>
      <div>{user?.isAuthenticated && user?.admin && <>Welcome admin.</>}</div>
    </div>
  )
}
