import { differenceInDays, isAfter, isBefore } from 'date-fns'
import * as React from 'react'
import { useCallback, useState } from 'react'
import {
  buildDateMaxTime,
  buildDateZeroTime,
  buildTodayZero,
  formatISODate,
  isWithin,
} from 'util/date'
import { generateAllRounds } from 'util/round'

const RoundsContext = React.createContext()

export { RoundsProvider, useRounds }

function RoundsProvider(props) {
  const { mainRounds } = props

  const [selectedRound, setSelectedRound] = useState(mainRounds.current)
  const allRounds = generateAllRounds()

  const isGameReleasedInRound = useCallback((game, round) => {
    return (
      round &&
      game &&
      game.released &&
      isWithin(
        game.released,
        buildDateZeroTime(formatISODate(round.startDate)),
        buildDateMaxTime(formatISODate(round.endDate))
      )
    )
  }, [])

  const isInCurrentRound = useCallback(
    (game) => {
      return isGameReleasedInRound(game, mainRounds.current)
    },
    [mainRounds, isGameReleasedInRound]
  )

  const buildRoundProgressPercent = (round) => {
    const today = buildTodayZero()

    if (isAfter(today, round.endDate)) {
      return 100
    }
    if (isBefore(today, round.startDate)) {
      return 0
    }

    const roundDays = differenceInDays(round.endDate, round.startDate)
    const diffInDays = differenceInDays(today, round.startDate)
    return (diffInDays / roundDays) * 100
  }

  const roundsContext = {
    selectedRound,
    setSelectedRound,
    allRounds,
    mainRounds,
    isInCurrentRound,
    isGameReleasedInRound,
    buildRoundProgressPercent,
  }

  return (
    <RoundsContext.Provider value={roundsContext} {...props}>
      {props.children}
    </RoundsContext.Provider>
  )
}

function useRounds() {
  const context = React.useContext(RoundsContext)
  if (!context) {
    throw new Error(`useRounds must be used within a RoundsProvider`)
  }
  return context
}
