import { ChildSpacerHAll } from 'Components/layout/ChildSpacer'
import { CSTooltip } from 'Components/visual/csTooltip'
import { useMemo } from 'react'
import { useAuth } from 'store/user/authContext'
import { colors } from 'styles/colors'
import {
  SCORE_LIKELY_THRESHOLD,
  SCORE_UNLIKELY_THRESHOLD,
} from 'util/gameLogic'

export const GameProfile = ({ game, showLabel }) => {
  const { user } = useAuth()

  const gameProfile = useMemo(() => {
    let gameProfile = { label: 'Score unlikely', color: colors.red }

    if (game.profilePoints > SCORE_LIKELY_THRESHOLD) {
      gameProfile.color = colors.positive
      gameProfile.label = 'Score likely'
    } else if (game.profilePoints >= SCORE_UNLIKELY_THRESHOLD) {
      gameProfile.color = colors.warning
      gameProfile.label = 'Score possible'
    }

    return gameProfile
  }, [game])

  return (
    <ChildSpacerHAll style={{ display: 'flex', alignItems: 'center' }}>
      <CSTooltip
        id={game.id}
        text={
          <span>
            {gameProfile.label}
            {user.admin && <span> ({game.profilePoints})</span>}
          </span>
        }
      >
        <span
          className="material-symbols-outlined"
          style={{
            color: gameProfile.color,
            display: 'flex',
            alignItems: 'center',
            fontSize: '18px',
          }}
        >
          bolt
        </span>
      </CSTooltip>
      {showLabel && (
        <span style={{ fontSize: '12px', color: gameProfile.color }}>
          {gameProfile.label}
        </span>
      )}
    </ChildSpacerHAll>
  )
}
