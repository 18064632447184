export function sortByStringValue(a, b, direction) {
  if (!a || !b || typeof a !== 'string' || typeof b !== 'string') {
    return 0
  }

  const nameComparison = a.toLowerCase().localeCompare(b.toLowerCase())
  return direction === 'DESC' ? -nameComparison : nameComparison
}

export function sortByReleasedAndName(a, b, direction) {
  if (!a || !b) {
    return 0
  }

  const releasedComparison = a.released
    .toLowerCase()
    .localeCompare(b.released.toLowerCase())
  if (releasedComparison === 0) {
    const nameComparison = a.name
      .toLowerCase()
      .localeCompare(b.name.toLowerCase())
    if (nameComparison) {
      return nameComparison
    }
  }

  return direction === 'DESC' ? -releasedComparison : releasedComparison
}

export function sortByProperty(a, b, col, direction) {
  if (!a || !b) {
    return 0
  }

  let aCol = a[col]
  if (!a[col]) {
    aCol = 0
  }

  let bCol = b[col]
  if (!b[col]) {
    bCol = 0
  }

  // Sort numbers normally
  if (typeof aCol === 'number' && typeof bCol === 'number') {
    const result = aCol - bCol
    return direction === 'DESC' ? -result : result
  }

  // Dont sort weird crap
  if (typeof aCol !== 'string' || typeof bCol !== 'string') {
    return 0
  }

  const result = aCol.toLowerCase().localeCompare(bCol.toLowerCase())

  return direction === 'DESC' ? -result : result
}
