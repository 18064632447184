import { useContext } from 'react'
import { useUID } from 'react-uid'

import { CacheContext } from './cacheContext'

const useCachedData = (
  url,
  initialValue,
  observe,
  transform,
  refreshIntervalMs,
  method
) => {
  const { getCachedItem } = useContext(CacheContext)

  const requestId = useUID()

  const item = getCachedItem(
    requestId,
    url,
    initialValue,
    observe,
    transform,
    refreshIntervalMs,
    method
  )

  return [item.isLoading, item.value]
}

export default useCachedData
