import { analyticsLogEvent, signOutFromFirebase } from 'Components/firebase'
import React, { useState } from 'react'
import { apiEndpoints } from 'showdown-api/api'
import { apiPost } from 'showdown-api/rest'
import { useGames } from './gameContext'
import { useUINotifs } from './uiNotificationsContext'

const WatchlistContext = React.createContext()

function WatchlistProvider({ ...props }) {
  const { children } = props

  const [savingGameId, setSavingGameId] = useState(null)
  const { setGameActionSnackState } = useUINotifs()

  const { watchedGames, setWatchedGames } = useGames()

  const onWatchGame = async (game, userTeamId) => {
    try {
      console.log('Adding game to watchlist')
      analyticsLogEvent('manage_games', { name: 'watch_game' })
      setSavingGameId(game.id)
      await apiPost(apiEndpoints.team_watchlist_add, {
        gameId: game.id,
        teamId: userTeamId,
      }) // errors are thrown and caught below

      // Update selected games
      const updatedWatchedGames = [
        ...watchedGames,
        { id: game.id, watched: true },
      ]
      setWatchedGames(updatedWatchedGames)

      setGameActionSnackState({
        isOpen: true,
        text: `${game.name} added to your watchlist`,
        severity: 'success',
      })

      let t = setTimeout(() => {
        setSavingGameId(null)
        clearTimeout(t)
      }, 400)

      return true
    } catch (error) {
      setSavingGameId(null)
      console.error('Add to watchlist game error: ', error.message)
      if (error.status === 403) {
        console.log('Not logged in')
        setGameActionSnackState({
          isOpen: true,
          text: `You need to log in first`,
          severity: 'info',
        })
        signOutFromFirebase()
      } else {
        setGameActionSnackState({
          isOpen: true,
          text: `Could not add ${game.name}`,
          severity: 'error',
        })
      }

      return false
    }
  }

  const onUnwatchGame = async (game, userTeamId) => {
    try {
      console.log('Dropping game')
      analyticsLogEvent('manage_games', { name: 'drop_game' })
      setSavingGameId(game.id)

      await apiPost(apiEndpoints.team_watchlist_drop, {
        gameId: game.id,
        teamId: userTeamId,
      })
      analyticsLogEvent('manage_games', { name: 'confirm_team_change' })

      // Remove game from selected games
      const updatedWatchedGames = watchedGames.filter(
        (selectedGame) => selectedGame.id !== game.id
      )
      setWatchedGames(updatedWatchedGames)

      setGameActionSnackState({
        isOpen: true,
        text: `${game.name} removed from your watchlist`,
        severity: 'success',
      })

      let t = setTimeout(() => {
        setSavingGameId(null)
        clearTimeout(t)
      }, 400)

      return true
    } catch (error) {
      setSavingGameId(null)
      console.error('Drop game error: ', error.message)
      if (error.status === 403) {
        console.log('Not logged in')
        setGameActionSnackState({
          isOpen: true,
          text: `You need to log in first`,
          severity: 'info',
        })
        signOutFromFirebase()
      } else {
        setGameActionSnackState({
          isOpen: true,
          text: `Could not remove ${game.name}.`,
          severity: 'error',
        })
      }

      return false
    }
  }

  const pickGameContext = {
    onWatchGame,
    onUnwatchGame,
    savingGameId,
  }

  return (
    <WatchlistContext.Provider value={pickGameContext}>
      {children}
    </WatchlistContext.Provider>
  )
}

function useWatchGame() {
  const context = React.useContext(WatchlistContext)
  if (!context) {
    throw new Error(`useWatchGame must be used within a WatchlistProvider`)
  }
  return context
}

export { useWatchGame, WatchlistProvider }
