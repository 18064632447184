import { SearchGameTextField } from 'Components/interactive/searchGameTextField'
import { useEffect, useState } from 'react'
import { useGameFilter } from 'store/game/gameFilterContext'
import useDebounce from 'util/useDebounce'

export const GameFilterName = () => {
  const { setSearch } = useGameFilter()

  const [nameFilter, setNameFilter] = useState('')
  const debouncedValue = useDebounce(nameFilter, 700)

  useEffect(() => {
    setSearch(debouncedValue)
  }, [setSearch, debouncedValue])

  const onChangeFilter = (event) => {
    setNameFilter(event.target.value)
  }

  return (
    <SearchGameTextField
      label="Search game"
      value={nameFilter}
      error={false}
      onChange={onChangeFilter}
      onClear={() => setNameFilter('')}
    />
  )
}
