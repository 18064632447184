import { SubtleText } from 'Components/visual/SubtleText'
import { VBox } from '../../layout/VBox'
import { buildPredictionGrade } from 'util/gameLogic'

export function PredictionBadge({ game, prediction }) {
  if (!prediction || prediction === 0) {
    return null
  }

  const diff = Math.abs(game.metacritic - prediction)

  let grade = buildPredictionGrade(diff)

  return (
    <VBox
      style={{ border: '1px solid #333', padding: 8, borderRadius: '8px' }}
      align="center"
    >
      Your prediction
      <div style={{ fontSize: '32px', lineHeight: '1.5em' }}>{prediction}</div>
      <div style={{ color: `${grade.color}` }}>{grade.gradeText}</div>
      {diff > 0 && <SubtleText text={grade.diffText}></SubtleText>}
    </VBox>
  )
}
