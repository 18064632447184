import { useMediaQuery } from '@mui/material'
import { HBox, VBox } from 'Components/layout'
import { ChildSpacerVAll } from 'Components/layout/ChildSpacer'
import { SubtleText } from 'Components/visual/SubtleText'
import { DarkList } from 'Components/visual/darkList'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRounds } from 'store/game/roundContext'
import { useLeaderboards } from 'store/leaderboard/leaderboardContext'
import { useAuth } from 'store/user/authContext'
import { query } from 'styles/mediaQueries'
import { roundPrecise } from 'util/math'
import BronzeMedal from './Bronze.svg'
import GoldMedal from './Gold.svg'
import SilverMedal from './Silver.svg'
import { transformPredictorLeaderboardList } from './leaderboardsDataTransform'

export const PredictorLeaderboardList = ({
  leaderboardList,
  label,
  mode,
  leaderboardsSortMode,
}) => {
  const { user } = useAuth()

  const { isLoadingLeaderboards } = useLeaderboards()

  const { selectedRound } = useRounds()

  const leaderboardsListData = useMemo(
    () =>
      transformPredictorLeaderboardList(
        leaderboardList,
        mode,
        leaderboardsSortMode
      ),
    [leaderboardList, mode, leaderboardsSortMode]
  )

  return (
    <VBox>
      <ChildSpacerVAll>
        <PredictorLeaderboardRenderer
          label={
            typeof label !== 'undefined' ? label : 'Predictor leaderboards'
          }
          leaderboardsListData={leaderboardsListData}
          selectedRound={selectedRound}
          isLoadingLeaderboards={isLoadingLeaderboards}
          user={user}
          mode={mode}
        />
      </ChildSpacerVAll>
    </VBox>
  )
}

const PredictorLeaderboardRenderer = ({
  label,
  leaderboardsListData,
  selectedRound,
  isLoadingLeaderboards,
  user,
}) => {
  const navigate = useNavigate()

  const medals = [
    <img src={GoldMedal} style={{ width: '40px' }} alt="Gold medal" />,
    <img src={SilverMedal} style={{ width: '40px' }} alt="Silver medal" />,
    <img src={BronzeMedal} style={{ width: '40px' }} alt="Bronze medal" />,
  ]

  const columns = [
    {
      flex: 1,
      align: 'center',
      ColComponent: ({ colData }) => {
        return (
          <>
            {colData?.entry?.place <= 3 &&
            colData.entry.scoreData.totalScore !== 0
              ? medals[colData?.entry?.place - 1]
              : colData?.entry?.place}
          </>
        )
      },
    },
    {
      flex: 9,
      ColComponent: ({ colData }) => {
        return (
          <VBox>
            {colData.entry.scoreData.totalScore >= 0 && (
              <>
                <HBox style={{ position: 'relative' }}>
                  {colData.entry.name}
                </HBox>
                <SubtleText
                  text={`${colData.entry.scoreData.totalPredicted} predictions`}
                />
              </>
            )}
          </VBox>
        )
      },
      align: 'left',
    },

    {
      ColComponent: ({ colData }) => {
        return (
          <>
            {colData.entry.scoreData.totalScore > 0 && (
              <VBox
                style={{
                  fontSize: '19px',
                  fontWeight: 'bold',
                }}
              >
                {colData.entry.scoreData.totalScore}p
                <SubtleText
                  text={`${
                    colData.entry.scoreData.totalScored
                  } scored (Avg. ${roundPrecise(
                    colData.entry.scoreData.totalScore /
                      colData.entry.scoreData.totalScored,
                    1
                  )}p)`}
                />
              </VBox>
            )}
          </>
        )
      },
    },
  ]

  const isMobile = useMediaQuery(query.mobile)

  return (
    <>
      <DarkList
        label={label}
        headerButtonLabel={typeof label !== 'undefined' && 'View leaderboards'}
        onButtonClick={() => navigate('/leaderboards?type=predictor')}
        list={leaderboardsListData}
        emptyText="No profiles signed up or predicted any games for the presented round yet."
        columns={columns}
        onRowClick={(rowData) => navigate('/teamPredictions/' + rowData.userId)}
        rowPadding={isMobile ? '16px' : '16px 16px 16px 0'}
        isLoading={isLoadingLeaderboards}
        markFn={(entry) => entry.userId && user.id && entry.userId === user.id}
      ></DarkList>
    </>
  )
}
