import { apiEndpoints } from 'showdown-api/api'
import * as React from 'react'
import { useState } from 'react'
import { buildRoundsFromIds } from 'util/round'
import { sortByProperty } from 'util/sort'
import useCachedData from 'util/useCachedData'

const LeaderboardsContext = React.createContext()

const LEADERBOARD_SORT_OPTIONS = {
  score: { label: 'Score', id: 'score' },
  scoreAvg: { label: 'Score Avg.', id: 'scoreAvg' },
}

function LeaderboardsProvider(props) {
  const [isLoadingLeaderboards, leaderboards] = useCachedData(
    apiEndpoints.leaderboard,
    {
      entries: [],
    },
    [],
    null,
    28800000
  )

  const [selectedLeaderboardType, setSelectedLeaderboardType] =
    useState('fantasy')

  const [leaderboardsSortMode, setLeaderboardsSortMode] = useState(
    LEADERBOARD_SORT_OPTIONS.score
  )

  const leaderboardsContext = {
    isLoadingLeaderboards,

    leaderboards: leaderboards.entries,

    leaderboardLastUpdatedDate: leaderboards.lastUpdated,

    leaderboardRounds: buildRoundsFromIds(
      Object.keys(leaderboards.entries)
    ).sort((a, b) => sortByProperty(a, b, 'id', 'DESC')),

    selectedLeaderboardType,
    setSelectedLeaderboardType,

    leaderboardsSortMode,
    setLeaderboardsSortMode,
    LEADERBOARD_SORT_OPTIONS,
  }

  return <LeaderboardsContext.Provider value={leaderboardsContext} {...props} />
}

function useLeaderboards() {
  const context = React.useContext(LeaderboardsContext)
  if (!context) {
    throw new Error(
      `useLeaderboards must be used within a LeaderboardsProvider`
    )
  }
  return context
}

export { LeaderboardsProvider, useLeaderboards }
