import { HBox } from 'Components/layout'
import { ChildSpacerV } from 'Components/layout/ChildSpacer'
import { SubtleText } from 'Components/visual/SubtleText'
import { DarkList, DarkListLoading } from 'Components/visual/darkList'
import { WinnerCrown } from 'pages/home/profilePanel/WinnerCrown'
import { useMemo } from 'react'
import { usePredictions } from 'store/game/predictionsContext'
import { useAuth } from 'store/user/authContext'
import { colors } from 'styles/colors'
import {
  getGamePredictionScore,
  getGamePredictions,
  isGameLocked,
  isGameReleased,
} from 'util/gameLogic'
import { sortByProperty } from 'util/sort'

export const GamePredictionsList = ({ game }) => {
  const { user } = useAuth()

  const userPrediction = getGamePredictionScore(user, game)

  const { isLoadingRoundGamePredictions, roundGamePredictions } =
    usePredictions()
  const gamePredictions = useMemo(() => {
    return getGamePredictions(roundGamePredictions, game.id)
  }, [roundGamePredictions, game])

  const sortedPredictions = useMemo(() => {
    return (
      gamePredictions?.teams
        .filter(
          (team) =>
            team.score > 0 && (userPrediction > 0 || isGameReleased(game))
        )
        .map((team) => {
          const distance =
            game.metacritic > 0
              ? Math.abs(team.score - game.metacritic)
              : team.score
          return { ...team, distance: distance }
        })
        .sort((a, b) => sortByProperty(a, b, 'distance', 'ASC')) ?? []
    )
  }, [game, userPrediction, gamePredictions])

  return (
    <ChildSpacerV>
      <GamePredictionsRenderer
        user={user}
        game={game}
        userPrediction={userPrediction}
        listData={sortedPredictions}
        isLoading={isLoadingRoundGamePredictions}
      />
      <div style={{ textAlign: 'right' }}>
        {sortedPredictions.length > 1 && `Average: ${gamePredictions?.average}`}
      </div>
    </ChildSpacerV>
  )
}

const GamePredictionsRenderer = ({
  user,
  game,
  userPrediction,
  listData,
  isLoading,
}) => {
  if (isLoading) {
    return (
      <div>
        <DarkListLoading />
      </div>
    )
  }

  const columns = [
    {
      flex: 6,
      ColComponent: ({ colData }) => {
        return (
          <HBox style={{ position: 'relative' }}>
            {colData.entry.name} <WinnerCrown entry={colData.entry} />
          </HBox>
        )
      },
      align: 'left',
    },
    {
      ColComponent: ({ colData }) => {
        return (
          <>
            <span
              style={{
                fontSize: '19px',
                fontWeight: 'bold',
              }}
            >
              {colData.entry.score}{' '}
              {game.metacritic > 0 && (
                <span
                  style={{
                    fontSize: '11px',
                    fontWeight: 'normal',
                    color: colors.pink,
                  }}
                >
                  {colData.entry.distance}
                </span>
              )}
            </span>
          </>
        )
      },
    },
  ]

  const gameLocked = isGameLocked(game)

  let listSubLabel = ''

  if (!isGameReleased(game)) {
    if (user.isAuthenticated) {
      if (userPrediction) {
        listSubLabel = ''
      } else {
        if (gameLocked) {
          listSubLabel =
            'No more predictions can be made. Too close to release.'
        } else {
          listSubLabel =
            'Enter your prediction to see all community predictions.'
        }
      }
    } else {
      listSubLabel =
        'Sign in to view predictions and enter your prediction for this review score.'
    }
  } else {
    if (listData.length === 0) {
      listSubLabel = 'No predictions were made.'
    }
  }

  return (
    <>
      {listData.length > 0 && (
        <DarkList
          isLoading={isLoading}
          list={listData}
          columns={columns}
        ></DarkList>
      )}
      {listSubLabel && <SubtleText>{listSubLabel}</SubtleText>}
    </>
  )
}
