import { Box, Button, Typography } from '@mui/material'
import { GameName } from 'Components/game/gameName'
import { GameProfile } from 'Components/game/gameProfile'
import { HBox, VBox } from 'Components/layout'
import { ChildSpacerH, ChildSpacerVAll } from 'Components/layout/ChildSpacer'
import { SubtleText } from 'Components/visual/SubtleText'
import { CSTooltip } from 'Components/visual/csTooltip'
import { DarkList } from 'Components/visual/darkList'
import { PredictionBadgeMini } from 'Components/visual/scoreBadge/predictionBadgeMini'
import { ScoreBadge } from 'Components/visual/scoreBadge/scoreBadge'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGames } from 'store/game/gameContext'
import { useAuth } from 'store/user/authContext'
import {
  findLowestScoredGame,
  getGamePredictionScore,
  isDropAfterReleaseAllowed,
  isDropAllowed,
  isGameReleased,
} from 'util/gameLogic'
import { sortByProperty } from 'util/sort'
import { ReleaseDate } from './releaseDate'

export const YourGamesList = ({ gameList, gamesOutsideList, gameLimit }) => {
  const { user } = useAuth()

  const { isLoadingUserGames } = useGames()

  const sortedGames = useMemo(() => {
    let sortedGames =
      gameList
        ?.sort((a, b) => sortByProperty(a, b, 'released', 'ASC'))
        .map((game) => {
          return {
            ...game,
            dropAllowed: isDropAllowed(game),
            dropAfterReleaseAllowed: isDropAfterReleaseAllowed(game),
            userPrediction: getGamePredictionScore(user, game),
          }
        }) ?? []

    const { scoredGamesCount, lowestScoreGameIndex } =
      findLowestScoredGame(sortedGames)

    if (scoredGamesCount >= 10 && lowestScoreGameIndex !== null) {
      sortedGames[lowestScoreGameIndex].lowest = true
    }

    return sortedGames
  }, [gameList, user])

  const sortedGamesOutsideRound = useMemo(() => {
    let sortedGamesOutsideRound =
      gamesOutsideList
        ?.sort((a, b) => sortByProperty(a, b, 'released', 'ASC'))
        .map((game) => {
          const gameDecorated = {
            ...game,
            selectedOverride: true,
            dropAllowed: isDropAllowed(game),
            dropAfterReleaseAllowed: isDropAfterReleaseAllowed(game),
          }
          return gameDecorated
        }) ?? []

    return sortedGamesOutsideRound
  }, [gamesOutsideList])

  // Columns
  const columns = [
    {
      ColComponent: ({ colData }) => {
        function ContentComponent() {
          return (
            <>
              <Typography>
                <strong>Lowest scoring game</strong>
                <br />
                At the end of the round the lowest scoring game won't count
                towards your total. This includes a game not scoring at all.
              </Typography>
            </>
          )
        }

        // Show badge if game is unreleased or is released and the logged in user made a prediction
        let showPredictionBadge = false

        if (
          (user.isAuthenticated && colData.entry.userPrediction > 0) ||
          (!isGameReleased(colData.entry) && !colData.entry.metacritic)
        ) {
          showPredictionBadge = true
        }

        return (
          <VBox align="center-left">
            <GameName game={colData.entry} />
            <div
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <GameProfile game={colData.entry} />
              <ReleaseDate
                game={colData.entry}
                enableFutureGameWarning={true}
              />

              <HBox align="center-right">
                <ChildSpacerH>
                  {showPredictionBadge && (
                    <>
                      <PredictionBadgeMini
                        score={colData.entry.userPrediction}
                      />
                    </>
                  )}
                  {colData.entry.metacritic && (
                    <>
                      {colData.entry.lowest ? (
                        <CSTooltip
                          id={'score' + colData.entry.id}
                          text={<ContentComponent />}
                        >
                          <span>
                            <ScoreBadge
                              game={colData.entry}
                              lowestScored={colData.entry.lowest}
                            />
                          </span>
                        </CSTooltip>
                      ) : (
                        <span>
                          <ScoreBadge
                            game={colData.entry}
                            lowestScored={colData.entry.lowest}
                          />
                        </span>
                      )}
                    </>
                  )}
                </ChildSpacerH>
              </HBox>
            </div>
          </VBox>
        )
      },
    },
  ]

  const navigate = useNavigate()
  return (
    <>
      <Box p={1} />
      {sortedGamesOutsideRound.length > 0 && (
        <>
          <DarkList
            isLoading={isLoadingUserGames}
            stackOnMobile={false}
            hoverEffect="scaleFX"
            label="Replace these games"
            subLabel="These games changed release date outside of the current round and no longer counts. Pick new games to replace them."
            emptyText=""
            list={sortedGamesOutsideRound}
            onRowClick={(rowData) => navigate('/game/' + rowData.slug)}
            columns={columns}
          ></DarkList>
          <Box p={1} />
        </>
      )}
      <DarkList
        isLoading={isLoadingUserGames}
        stackOnMobile={false}
        hoverEffect="scaleFX"
        subLabel={
          isLoadingUserGames ? '' : `${sortedGames.length}/${gameLimit} picked`
        }
        progress={(sortedGames.length / gameLimit) * 100}
        emptyText={
          <>
            <ChildSpacerVAll>
              <div>
                Pick up to 10 games to your profile on the{' '}
                <strong>Games</strong> page to play for a spot in the Picks
                standings.
              </div>
            </ChildSpacerVAll>
            <Button
              variant="outlined"
              onClick={() => {
                navigate('/games')
              }}
              style={{ width: '100%' }}
            >
              Go to Games
            </Button>
          </>
        }
        list={sortedGames}
        onRowClick={(rowData) => navigate('/game/' + rowData.slug)}
        columns={columns}
      ></DarkList>

      <SubtleText>
        The lowest scoring game in a round will not be counted if you score 10
        games.
      </SubtleText>
    </>
  )
}
