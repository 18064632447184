import { Box } from '@mui/material'
import { CSButton } from 'Components/game/CSButton'
import { HBox } from 'Components/layout'
import { Cols } from 'Components/layout/cols'
import { HLine } from 'Components/visual/HLine'
import { isAfter } from 'date-fns'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { apiEndpoints } from 'showdown-api/api'
import { apiPost } from 'showdown-api/rest'
import { useGames } from 'store/game/gameContext'
import { useRounds } from 'store/game/roundContext'
import { useAuth } from 'store/user/authContext'
import { sortByStringValue } from 'util/sort'

export const RoundSelector = ({ rounds, selectedRound, setSelectedRound }) => {
  const { mainRounds } = useRounds()
  const navigate = useNavigate()

  const { user } = useAuth()

  const { setUserGames, setIsLoadingUserGames } = useGames()

  const handleChange = async (newRoundId) => {
    const foundRound = rounds.find((round) => round.id === newRoundId)
    if (foundRound) {
      setSelectedRound(foundRound)

      if (
        user.isAuthenticated &&
        Object.hasOwn(user?.team?.rounds, foundRound.id)
      ) {
        setIsLoadingUserGames(true)
        const userGames = await apiPost(apiEndpoints.games_by_id, {
          gameIds: user.team.rounds[foundRound.id],
        })
        setUserGames(userGames?.data ?? [])
        setIsLoadingUserGames(false)
      } else {
        setUserGames([])
      }
      navigate(-1)
    }
  }

  const roundsCached = useMemo(() => {
    const allRounds = { current: [], future: [], past: [] }

    rounds.forEach((round) => {
      if (round.id === mainRounds.current.id) {
        allRounds.current.push(round)
      } else if (isAfter(round.startDate, mainRounds.current.endDate)) {
        allRounds.future.push(round)
      } else {
        allRounds.past.push(round)
      }
    })

    allRounds.future = allRounds.future.sort((a, b) =>
      sortByStringValue(a.id, b.id, 'ASC')
    )

    allRounds.past = allRounds.past.sort((a, b) =>
      sortByStringValue(a.id, b.id, 'DESC')
    )

    return allRounds
  }, [rounds, mainRounds])

  return (
    <>
      <Box p={5} />
      <HLine>Active round</HLine>
      <Box p={3} />
      {roundsCached.current.length > 0 && selectedRound && (
        <Cols set="1fr" gap={16}>
          {roundsCached.current.map((round) => (
            <div key={round.id} style={{ display: 'flex' }}>
              {selectedRound.id !== round.id && (
                <CSButton
                  onClick={() => handleChange(round.id)}
                  key={round.id}
                  value={round.id}
                  style={{ width: '100%' }}
                  label={<>{round.fullName} </>}
                />
              )}
              {selectedRound.id === round.id && (
                <HBox align="center" style={{ width: '100%', margin: 2 }}>
                  {round.fullName} (Selected)
                </HBox>
              )}
            </div>
          ))}
        </Cols>
      )}
      <Box p={5} />
      <HLine>Upcoming rounds</HLine>
      <Box p={3} />
      {roundsCached.future.length > 0 && selectedRound && (
        <Cols set="1fr" gap={16}>
          {roundsCached.future.map((round) => (
            <div key={round.id} style={{ display: 'flex' }}>
              {selectedRound.id !== round.id && (
                <CSButton
                  onClick={() => handleChange(round.id)}
                  key={round.id}
                  value={round.id}
                  style={{ width: '100%' }}
                  label={<>{round.fullName} </>}
                />
              )}
              {selectedRound.id === round.id && (
                <HBox align="center" style={{ width: '100%', margin: 2 }}>
                  {round.fullName} (Selected)
                </HBox>
              )}
            </div>
          ))}
        </Cols>
      )}
      <Box p={5} />
      <HLine>Past rounds</HLine>
      <Box p={3} />
      {roundsCached.past.length > 0 && selectedRound && (
        <Cols set="1fr" gap={16}>
          {roundsCached.past.map((round) => (
            <div key={round.id} style={{ display: 'flex' }}>
              {selectedRound.id !== round.id && (
                <CSButton
                  onClick={() => handleChange(round.id)}
                  key={round.id}
                  value={round.id}
                  style={{ width: '100%' }}
                  label={<>{round.fullName} </>}
                />
              )}
              {selectedRound.id === round.id && (
                <HBox align="center" style={{ width: '100%', margin: 2 }}>
                  {round.fullName} (Selected)
                </HBox>
              )}
            </div>
          ))}
        </Cols>
      )}
      <Box p={2} />
    </>
  )
}
