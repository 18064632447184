/* eslint-disable import/no-anonymous-default-export */
export default {
  mobilePortrait: 680,
  mobile: 850,
  mobileLandscape: 1050,
  tablet: 1210,
  desktop: 1400,
  desktopL: 1600,
  desktopXL: 1900,
  desktopUltra: 2500,
}
