import { GameLock } from 'Components/visual/gameLock'
import { GameStaleWarning } from 'Components/visual/gameStaleWarning'
import { ReleaseDateFormatter } from './releaseDateFormatter'
import { ChildSpacerHAll } from 'Components/layout/ChildSpacer'

export const ReleaseDate = ({ game, enableFutureGameWarning }) => {
  if (!game || !game.released) {
    return null
  }

  return (
    <ChildSpacerHAll>
      <ReleaseDateFormatter releaseDate={game.released} />
      <GameStaleWarning
        game={game}
        vAlign="text-bottom"
        enableFutureGameWarning={enableFutureGameWarning}
      />
      <GameLock game={game} vAlign="text-bottom" />
    </ChildSpacerHAll>
  )
}
