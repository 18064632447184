import { Box } from '@mui/material'
import { ChildSpacerVAll } from 'Components/layout/ChildSpacer'
import { AboutUs } from './aboutUs'
import { Contact } from './contact'

export const MobileFooter = () => {
  return (
    <ChildSpacerVAll>
      <Box p={4} />
      <Contact />
      <Box p={4} />
      <AboutUs />
      <Box p={2} />
    </ChildSpacerVAll>
  )
}
