import { Cols } from 'Components/layout/cols'
import { RoundSwitch } from 'Components/panes/roundSwitch'
import { Logo } from 'Components/visual'
import { Breakpoint } from 'react-socks'
import { colors } from 'styles/colors'
import { query } from 'styles/mediaQueries'
import { spacing } from 'styles/spacing'
import { TopMenu } from './topMenu'
import { RoundSwitchMobile } from 'Components/panes/roundSwitchMobile'
import { RoundTimeLine } from 'Components/panes/roundTimeLine'
import { Box } from '@mui/material'
export const MobileHeader = () => {
  const headerDesktopStyle = {
    left: 0,
    top: 0,
    padding: `${spacing.pageDesktop} ${spacing.pageDesktop} 0 ${spacing.pageDesktop}`,
    backgroundColor: colors.appBackground,
    zIndex: 2,
    borderBottom: `1px solid ${colors.appBackground}`,
  }

  const headerTabletStyle = {
    ...headerDesktopStyle,
    padding: `${spacing.pageTablet} ${spacing.pageTablet} 0 ${spacing.pageTablet}`,
  }

  const headerMobileStyle = {
    ...headerDesktopStyle,
    padding: `${spacing.pageMobile} ${spacing.pageMobile} 0 ${spacing.pageMobile}`,
  }

  return (
    <>
      <Breakpoint customQuery={query.mobile}>
        <header style={headerMobileStyle}>
          <Cols set="1fr 2fr" gap={32}>
            <Logo />
            <RoundSwitchMobile />
          </Cols>
          <RoundTimeLine />
          <Box p={5}></Box>
        </header>
      </Breakpoint>
      <Breakpoint customQuery={query.mobileToTablet}>
        <header style={headerTabletStyle}>
          <Cols set="1fr 2fr" gap={32}>
            <Logo />
            <RoundSwitchMobile />
          </Cols>
          <RoundTimeLine />
          <Box p={5}></Box>
        </header>
      </Breakpoint>
      <Breakpoint customQuery={query.tabletToDesktopUltra}>
        <header style={headerTabletStyle}>
          <Cols set="auto auto auto" gap={32}>
            <Logo />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <TopMenu />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <RoundSwitch />
            </div>
          </Cols>
        </header>
      </Breakpoint>
      <Breakpoint customQuery={query.desktopUltraUp}>
        <header style={headerDesktopStyle}>
          <Cols set="auto auto auto" gap={32}>
            <Logo />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <TopMenu />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <RoundSwitch />
            </div>
          </Cols>
        </header>
      </Breakpoint>
    </>
  )
}
