import { ChildSpacerV } from 'Components/layout/ChildSpacer'
import { SubtleText } from 'Components/visual/SubtleText'
import { TextSqueezer } from 'Components/visual/textWrapper'
import React from 'react'

export const PrivacyPolicy = () => {
  return (
    <TextSqueezer>
      <h4>Privacy and Cookies</h4>
      <ChildSpacerV>
        <div>
          <h5>Your information</h5>
          <SubtleText
            text="We don't ask for your name or any other personal information other than your email. Your email and password are transferred and stored securely in Google
        Firebase. We have no access to the password after it has been sent but a
        reset can be requested."
          />
        </div>
        <div>
          <h5>Cookies and storage</h5>
          <SubtleText text="We store a JSON Web Token to authenticate a logged in user and Google Analytics and Hotjar used to collect feedback and how the site is used so we can learn and improve." />
        </div>
        <div>
          <h5>Emails</h5>
          <SubtleText
            text="We don't like spam and will only send email for occasional updates about
        the game. Let us know if any emails cross the line for you."
          />
        </div>
      </ChildSpacerV>
    </TextSqueezer>
  )
}
