import { signOutFromFirebase } from 'Components/firebase'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { apiEndpoints } from 'showdown-api/api'
import { browserState } from 'store/browserState'
import { useRounds } from 'store/game/roundContext'
import { generateAllRoundsForUser } from 'util/round'

const AuthContext = React.createContext()

function AuthContextProvider(props) {
  const { children } = props

  const { selectedRound } = useRounds()

  const [isUserLoading, setIsUserLoading] = useState(true)
  const [user, setUser] = useState({ isAuthenticated: false })

  // Rounds
  const [allUserRounds, setAllUserRounds] = useState([])

  useEffect(() => {
    const checkUserLogin = async () => {
      try {
        setIsUserLoading(true)
        const authToken = browserState.getAuthToken()
        if (!authToken) {
          console.log('No auth token. User need to sign in.', authToken)
          setIsUserLoading(false)
          return
        }

        const result = await signInUser(authToken)
        console.log('signInUserResult', result)
        setIsUserLoading(false)
      } catch (error) {
        setIsUserLoading(false)
        console.log(error)
      }
    }
    checkUserLogin()
    // eslint-disable-next-line
  }, [])

  const fetchUserData = async (authToken) => {
    axios.defaults.headers.common = {
      Authorization: `Bearer ${authToken}`,
    }

    try {
      const userResponse = await axios.get(apiEndpoints.user)
      return { status: 'OK', userData: userResponse.data }
    } catch (error) {
      console.log(error)
      return { status: 'ERROR', userData: null } // NO_USER - temp fix for signup screen showing up
    }
  }

  const signInUser = async (authToken) => {
    try {
      const userDataResult = await fetchUserData(authToken)
      if (userDataResult.status === 'OK') {
        browserState.setAuthToken(authToken)
        setUser({
          isAuthenticated: true,
          hasAccount: true,
          isSignedIn: true,
          ...userDataResult.userData,
        })
        const allUserRounds = generateAllRoundsForUser(
          userDataResult.userData,
          selectedRound
        )
        setAllUserRounds(allUserRounds)
      } else if (userDataResult.status === 'NO_USER') {
        browserState.setAuthToken(authToken)
        setUser({ isAuthenticated: false, hasAccount: false, isSignedIn: true })
        return false
      } else {
        // Assume error
        console.log(userDataResult)
        setUnauthenticated()
        return {
          general: 'Technical error.',
        }
      }

      return true
    } catch (error) {
      console.log(error)
      setUnauthenticated()
      return {
        general:
          (error.response && error.response.data.error) ?? 'Technical error.',
      }
    }
  }

  const setUnauthenticated = () => {
    setUser({ isAuthenticated: false, isSignedIn: false })
    browserState.clearAuthToken()
  }

  const signOut = async () => {
    try {
      await signOutFromFirebase()
      setUnauthenticated()
      browserState.clearLoginProvider()
    } catch (error) {
      console.error(error)
    }
  }

  const authContext = {
    user,
    setUser,
    setIsUserLoading,
    isUserLoading,
    signInUser,
    signOut,
    allUserRounds,
  }

  return (
    <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  )
}

function useAuth() {
  const context = React.useContext(AuthContext)
  if (!context) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context
}

export { AuthContextProvider, useAuth }
