import { Paper, useMediaQuery } from '@mui/material'

import { ReleaseDate } from 'pages/home/profilePanel/releaseDate'
import { query } from 'styles/mediaQueries'
import { GameCardImage } from './gameCardImage'
import { GamePredictionCardContent } from './gamePredictionCardContent'

export const GamePredictionCard = ({ game }) => {
  const isMobile = useMediaQuery(query.mobile)

  return (
    <div style={{ position: 'relative', display: 'flex', flex: 1 }}>
      <Paper
        style={{
          display: 'flex',
          position: 'relative',
          flex: 1,
          height: isMobile ? 180 : 180,
          overflow: 'hidden',
        }}
      >
        <GameCardImage game={game} />
        <GamePredictionCardContent game={game} />
        <div
          style={{
            whiteSpace: 'nowrap',
            fontSize: '12px',
            position: 'absolute',
            left: '16px',
            bottom: '16px',
          }}
        >
          <div>{game.tba ? 'No date' : <ReleaseDate game={game} />}</div>
        </div>
      </Paper>
    </div>
  )
}
