import { IconButton, InputAdornment, TextField } from '@mui/material'

export const SearchGameTextField = ({
  label,
  value,
  fullWidth,
  onChange,
  validate,
  onClear,
  ...props
}) => {
  const id = label.replace(/\s/g, '_').toLowerCase()

  return (
    <TextField
      hiddenLabel
      size="small"
      id={id}
      name={id}
      placeholder={label}
      value={value}
      variant={props.variant ?? 'filled'}
      onChange={onChange}
      {...props}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <span className="material-symbols-outlined">search</span>
          </InputAdornment>
        ),
        endAdornment: (
          <>
            {value && (
              <IconButton onClick={onClear} color={props.color ?? 'primary'}>
                <span className="material-symbols-outlined">close</span>
              </IconButton>
            )}
          </>
        ),
      }}
    />
  )
}
