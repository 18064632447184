export const Rows = ({ set, gap, margin, style, children }) => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateRows: set,
        gridGap: gap,
        margin: margin,
        ...style,
      }}
    >
      {children}
    </div>
  )
}
