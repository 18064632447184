import { UserScore } from 'Components/visual/profileScore'
import { useEffect, useState } from 'react'
import { useLeaderboards } from 'store/leaderboard/leaderboardContext'
import { sortByProperty } from 'util/sort'

export const UserPredictionsSummary = ({ user, selectedRound }) => {
  const { leaderboards } = useLeaderboards()
  const [profileScore, setProfileScore] = useState(0)

  useEffect(() => {
    const leaderboard = leaderboards[selectedRound.id]
      ? leaderboards[selectedRound.id].predictor
      : []
    const teamPosition =
      leaderboard
        .sort((a, b) =>
          sortByProperty(a.scoreData, b.scoreData, 'totalScore', 'DESC')
        )
        .findIndex((entry) => entry.userId === user.id) ?? 0
    const team = leaderboard.find((entry) => entry.userId === user.id) ?? {
      scoreData: {
        totalScore: 0,
      },
    }

    setProfileScore({
      score: (team && team.scoreData.totalScore) ?? 0,
      position: teamPosition,
    })
  }, [user, selectedRound.id, leaderboards])

  return <UserScore profileScore={profileScore} />
}
