import * as React from 'react'
import { useState } from 'react'

const SignupViewContext = React.createContext()

export { SignUpViewContextProvider, useSignUpViewContext }

function SignUpViewContextProvider(props) {
  const [openSignupDrawer, setOpenSignupDrawer] = useState(false)

  const signUpViewContext = {
    openSignupDrawer,
    setOpenSignupDrawer,
  }

  return <SignupViewContext.Provider value={signUpViewContext} {...props} />
}

function useSignUpViewContext() {
  const context = React.useContext(SignupViewContext)
  if (!context) {
    throw new Error(
      `useSignUpViewContext must be used within a SignUpViewContextProvider`
    )
  }
  return context
}
