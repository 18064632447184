import { sortByProperty } from 'util/sort'

export const transformPredictorLeaderboardList = (
  leaderboardList,
  mode,
  leaderboardsSortMode
) => {
  return (
    leaderboardList
      ?.filter((entry) => entry.scoreData.totalPredicted > 0)
      ?.sort((a, b) => sortByProperty(a, b, 'name', 'ASC'))
      ?.sort((a, b) =>
        sortByProperty(a.scoreData, b.scoreData, 'totalPredicted', 'DESC')
      )
      ?.sort((a, b) =>
        sortByProperty(a.scoreData, b.scoreData, 'totalScore', 'DESC')
      )
      .slice(0, mode === 'compact' ? 5 : leaderboardList.length)
      .map((entry, index) => {
        let place = index
        return {
          ...entry,
          place: place + 1,
        }
      })
      .sort((a, b) =>
        sortByProperty(a, b, leaderboardsSortMode?.id ?? 'score', 'DESC')
      ) ?? []
  )
}

export const transformFantasyLeaderboardList = (
  leaderboardList,
  mode,
  leaderboardsSortMode,
  user,
  isCurrentRound,
  currentUserSelectedGames
) => {
  const prev = { place: 0, score: 0, scoredGameCount: 0 }

  return (
    leaderboardList
      ?.filter((entry) => entry.pickedGameCount > 0)
      ?.sort((a, b) => sortByProperty(a, b, 'name', 'ASC'))
      ?.sort((a, b) => sortByProperty(a, b, 'pickedGameCount', 'DESC'))
      ?.sort((a, b) => sortByProperty(a, b, 'score', 'DESC'))
      .slice(0, mode === 'compact' ? 5 : leaderboardList.length)
      .map((entry, index) => {
        let place = index
        if (index === 0) {
          prev.place = index
          prev.score = entry.score
          prev.scoredGameCount = entry.scoredGameCount
        } else {
          if (entry.score === prev.score) {
            place = prev.place
          }
        }

        const gap = entry.score - prev.score
        const pickedGameCount =
          isCurrentRound && entry.userId === user?.id
            ? currentUserSelectedGames.length
            : entry.pickedGameCount

        return {
          ...entry,
          gap,
          place: place + 1,
          scoredPickedGameCount:
            entry.scoredGameCount === 0
              ? `${entry.pickedGameCount} picked`
              : `${entry.scoredGameCount} scored / ${pickedGameCount} picked`,
        }
      })
      .sort((a, b) =>
        sortByProperty(a, b, leaderboardsSortMode?.id ?? 'score', 'DESC')
      ) ?? []
  )
}
