import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'

export const GameFilterScore = ({ gameFilter, setGameFilter }) => {
  const handleChange = (event, score) => {
    setGameFilter({
      ...gameFilter,
      score: {
        ...gameFilter.score,
        [score]: event.target.checked,
      },
    })
  }

  return (
    <>
      <FormGroup>
        <h4>Score</h4>
        <FormControlLabel
          control={
            <Checkbox
              checked={gameFilter.score.platinum}
              onChange={(event) => handleChange(event, 'platinum')}
            />
          }
          label="Platinum (90+)"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={gameFilter.score.gold}
              onChange={(event) => handleChange(event, 'gold')}
            />
          }
          label="Gold (80-89)"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={gameFilter.score.silver}
              onChange={(event) => handleChange(event, 'silver')}
            />
          }
          label="Silver (70-79)"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={gameFilter.score.bronze}
              onChange={(event) => handleChange(event, 'bronze')}
            />
          }
          label="Bronze (60-69)"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={gameFilter.score.iron}
              onChange={(event) => handleChange(event, 'iron')}
            />
          }
          label="Iron (0-59)"
        />
      </FormGroup>
    </>
  )
}
