import { WordDotList } from 'Components/visual/WordDotList'

export function InvolvedCompanies({ list }) {
  if (!list || list?.length <= 0) return null

  const involvedCompanies = list.map((involvedCompany) => {
    const roles = []
    if (involvedCompany.developer) roles.push('Developer')
    if (involvedCompany.publisher) roles.push('Publisher')
    if (involvedCompany.porting) roles.push('Porting')
    if (involvedCompany.supporting) roles.push('Supporting')

    return { name: involvedCompany.company.name, roles }
  })

  return (
    <>
      <h4>Who were involved in making the game?</h4>
      {involvedCompanies.map((company, index) => (
        <div key={index}>
          <div>
            {company.name} (<WordDotList list={company.roles} />)
          </div>
        </div>
      ))}
    </>
  )
}
