export function buildResizeLink(link, size) {
  let resizeLink = link.replace(
    /\/screenshots/,
    `/resize/${size}/-/screenshots`
  )
  resizeLink = resizeLink.replace(/\/games/, `/resize/${size}/-/games`)
  return resizeLink
}

/*
the size parameter values are:
cover_small	90 x 128	Fit
cover_big	264 x 374	Fit

logo_med	284 x 160	Fit
screenshot_big	889 x 500	Lfill, Center gravity
screenshot_med	569 x 320	Lfill, Center gravity
screenshot_huge	1280 x 720	Lfill, Center gravity

thumb	90 x 90	Thumb, Center gravity
micro	35 x 35	Thumb, Center gravity

720p	1280 x 720	Fit, Center gravity
1080p	1920 x 1080	Fit, Center gravity  

appending _2x to any size, you can get retina
*/

export function buildIgdbImageLink(imageId, size) {
  return `https://images.igdb.com/igdb/image/upload/t_${size}/${imageId}.jpg`
}
