import { Button } from '@mui/material'
import { useState } from 'react'
import { CSDrawer } from './drawer'

export const FilterButtonDrawer = ({
  drawerButtonLabel,
  anchor,
  ContentComponent,
  ...props
}) => {
  const [openDrawer, setOpenDrawer] = useState(false)

  return (
    <>
      <CSDrawer
        anchor={anchor ?? 'left'}
        drawerOpen={openDrawer}
        setDrawerOpen={setOpenDrawer}
        ContentComponent={ContentComponent}
        {...props}
      />

      <Button
        variant="outlined"
        color="primary"
        onClick={() => setOpenDrawer(true)}
        startIcon={<span className="material-symbols-outlined">tune</span>}
      >
        {drawerButtonLabel}
      </Button>
    </>
  )
}
