import { addDays, differenceInDays, isAfter, parseISO } from 'date-fns'
import { colors } from 'styles/colors'
import { buildTodayZero, daysLeft } from './date'

export const isGameStale = (game) => {
  const daysAfterRelease = daysLeft(game.released)
  return daysAfterRelease <= -7 && game.metacritic === null
}

export const isGameMovedToFuture = (game, currentRound) => {
  const movedToFuture = isAfter(parseISO(game.released), currentRound.endDate)
  return movedToFuture
}

export const isGameReleased = (game) => {
  return isAfter(addDays(buildTodayZero(), 1), parseISO(game.released))
}

/*
  drops are allowed when:
  game has been not been released or have been released for 3 days and still haven't scored
  game is not locked (3 days before release)
  game has not scored

  warn confirm for drop when:
  game has not scored
  7 days since release have passed
*/

export const isDropAfterReleaseAllowed = (game) => {
  return differenceInDays(buildTodayZero(), new Date(game.released)) >= 3
}

export const isDropAllowed = (game) => {
  return (
    !isGameScored(game) &&
    !isGameLocked(game) &&
    (!isGameReleased(game) || isDropAfterReleaseAllowed(game))
  )
}

export const isGameScored = (game) => {
  return game.metacritic && game.metacritic >= 1
}

export const isGameLocked = (game) => {
  const daysLeftToRelease = daysLeft(game.released)
  return daysLeftToRelease >= -1 && daysLeftToRelease <= 3
}

export const isGameReviewed = (game) => {
  return game.metacritic !== null
}

export function clamp(x, min, max) {
  return Math.min(Math.max(x, min), max)
}

function calcScoreLevel(game) {
  return clamp(game.metacritic - 50, 0, 100)
}

function calcHypeLevel(game) {
  return game.suggestions_count
}

export const isCookie = (game) => {
  if (game.metacritic >= 70 && game.suggestions_count > 0) {
    let scoreLevel = calcScoreLevel(game)
    let hypeLevel = calcHypeLevel(game)
    let surpriseRatio = scoreLevel / hypeLevel
    return surpriseRatio >= 0.21
  }

  return false
}

export const isBomb = (game) => {
  if (
    game.metacritic > 0 &&
    game.metacritic < 80 &&
    game.suggestions_count > 0
  ) {
    let scoreLevel = calcScoreLevel(game)
    let hypeLevel = calcHypeLevel(game)
    let surpriseRatio = scoreLevel / hypeLevel
    return surpriseRatio <= 0.032
  }

  return false
}

export const isShadowDrop = (game) => {
  if (game && game.csTags) {
    return game.csTags.includes('shadowDrop')
  }

  return false
}

export const buildBadgeCounts = (gameList) => {
  const badgeCounters = {
    iron: 0,
    bronze: 0,
    silver: 0,
    gold: 0,
    ultra: 0,
    notScored: 0,
  }

  gameList?.forEach((game) => {
    if (game.metacritic) {
      if (game.metacritic >= 90) {
        badgeCounters.ultra++
      } else if (game.metacritic >= 80) {
        badgeCounters.gold++
      } else if (game.metacritic >= 70) {
        badgeCounters.silver++
      } else if (game.metacritic >= 60) {
        badgeCounters.bronze++
      } else {
        badgeCounters.iron++
      }
    } else {
      badgeCounters.notScored++
    }
  })

  return badgeCounters
}

export const hasPredictionForGame = (user, game) => {
  return (
    (user?.team?.predictions &&
      user.team.predictions.hasOwnProperty(game.id)) ??
    false
  )
}

export const getGamePredictionScore = (user, game) => {
  if (!hasPredictionForGame(user, game)) {
    return null
  }

  return user.team.predictions[game.id]
}

export const getGamePredictions = (roundGamePredictions, gameId) => {
  const foundRoundGamePrediction =
    roundGamePredictions?.filter(
      (roundGamePredictions) => roundGamePredictions.id === gameId
    ) ?? null

  if (foundRoundGamePrediction.length > 0) {
    return foundRoundGamePrediction[0].predictions
  }

  return null
}

export const predictionScoringTable = {
  0: 15,
  1: 10,
  2: 7,
  3: 5,
  4: 2,
  5: 1,
}

export const calcPredictionPoints = (diff) => {
  if (diff < 0 || diff > 5) {
    return null
  }

  return predictionScoringTable[diff]
}

export const buildPredictionGrade = (diff) => {
  let grade = { gradeText: '', diffText: '', color: 'white' }
  if (diff === 0) {
    grade = { gradeText: 'Perfect!', diffText: '', color: colors.mintText }
  } else if (diff === 1) {
    grade = {
      gradeText: 'Masterful!',
      diffText: diff + ' from perfect',
      color: colors.mintText,
    }
  } else if (diff === 2) {
    grade = {
      gradeText: 'Awesome!',
      diffText: diff + ' from perfect',
      color: colors.mintText,
    }
  } else if (diff === 3) {
    grade = {
      gradeText: 'Great!',
      diffText: diff + ' from perfect',
      color: colors.mintText,
    }
  } else if (diff === 4) {
    grade = {
      gradeText: 'Good!',
      diffText: diff + ' from perfect',
      color: colors.mintText,
    }
  } else if (diff <= 5) {
    grade = {
      gradeText: 'Not bad',
      diffText: diff + ' from perfect',
      color: 'mintText',
    }
  }

  return grade
}
export const findLowestScoredGame = (gameList) => {
  let lowestScore = 100
  let lowestScoreGameIndex = null
  let scoredGamesCount = 0
  for (let gameIndex = 0; gameIndex < gameList.length; gameIndex++) {
    const game = gameList[gameIndex]
    if (game.metacritic > 0) {
      scoredGamesCount++
    }
    if (game.metacritic && game.metacritic < lowestScore) {
      lowestScore = game.metacritic
      lowestScoreGameIndex = gameIndex
    }
  }

  return { scoredGamesCount, lowestScoreGameIndex }
}

export const SCORE_UNLIKELY_THRESHOLD = 150
export const SCORE_LIKELY_THRESHOLD = 250
