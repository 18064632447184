import { Skeleton } from '@mui/material'
import { CardGrid } from 'Components/game/cardGrid'
import { GamePredictionCard } from 'Components/game/gamePredictionCard'
import { PreviewDrawer } from 'Components/interactive/previewDrawer'
import { ChildSpacerVAll } from 'Components/layout/ChildSpacer'
import { HLine } from 'Components/visual/HLine'
import { SubtleText } from 'Components/visual/SubtleText'
import { Text } from 'Components/visual/text'
import { Link } from 'react-router-dom'
import { useGames } from 'store/game/gameContext'
import { usePredictGame } from 'store/game/userPredictGameContext'
import { useAuth } from 'store/user/authContext'
import { semanticMedia } from 'styles/semanticMedia'
import { semanticSurface } from 'styles/semanticSurface'

export const UserPredictList = () => {
  const { isLoadingAvailableGames } = useGames()
  const { user } = useAuth()

  const { gamesToPredict, gamesPossibleToPredict } = usePredictGame()

  return (
    <ChildSpacerVAll>
      <PreviewDrawer
        anchor="left"
        previewItemLimit={1}
        ContentComponent={PredictGameGrid}
        isLoading={isLoadingAvailableGames}
        gamesToPredict={gamesToPredict}
        gamesPossibleToPredict={gamesPossibleToPredict}
        user={user}
      />
    </ChildSpacerVAll>
  )
}

function PredictGameGrid({
  user,
  limit,
  isLoading,
  gamesToPredict,
  gamesPossibleToPredict,
}) {
  const mainTitle = `What's your prediction?`
  const altTitle = 'Predict the review score'

  if (isLoading) {
    return (
      <>
        <HLine>{altTitle}</HLine>
        <Skeleton
          variant="rectangular"
          height={200}
          sx={{ borderRadius: 2 }}
          style={{ backgroundColor: semanticSurface.surfaceLayerLow }}
        />
      </>
    )
  }

  if (gamesPossibleToPredict.length <= 0) {
    return (
      <>
        <HLine>{altTitle}</HLine>
        <div style={{ color: semanticMedia.contrastMedium }}>
          <p>
            There are no games left to predict for the next two weeks right now.
          </p>
          <Text color="contrastLow">
            There may be more to predict under <Link to="/games">Games</Link> if
            you want to catch them all.
          </Text>
        </div>
      </>
    )
  }

  if (gamesToPredict.length <= 0) {
    return (
      <>
        <HLine>{altTitle}</HLine>

        <div style={{ color: semanticMedia.contrastMedium }}>
          <p>
            Way to go! You have predicted review scores for every game releasing
            in the next 2 weeks.
          </p>
          <Text color="contrastLow">
            There may be more to predict under <Link to="/games">Games</Link> if
            you want to catch them all.
          </Text>
        </div>
      </>
    )
  }

  return (
    <>
      <HLine>{mainTitle}</HLine>

      <div style={{ color: semanticMedia.contrastMedium }}>
        Enter which exact review score you think the following game will
        receive. The closer you are, the more points you get for the predictions
        mode!
      </div>
      <CardGrid
        limit={limit}
        list={gamesToPredict}
        isLoading={isLoading}
        Component={GamePredictionCard}
        readonly={true}
        emptyText="Way to go! You have predicted review scores for every game releasing
          in next two weeks. There may be more to predict under Games if you want to catch them all."
      />
      {user.isAuthenticated && gamesToPredict.length > 0 && (
        <SubtleText>
          Predict the review score for {gamesToPredict.length} more{' '}
          {gamesToPredict.length > 1 ? 'games' : 'game'} releasing within two
          weeks.
        </SubtleText>
      )}
    </>
  )
}
