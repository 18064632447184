import { HBox, VBox } from 'Components/layout'
import { useMemo } from 'react'
import AndroidIcon from './platform-android.svg'
import AppleIcon from './platform-apple.svg'
import PlaystationIcon from './platform-playstation.svg'
import SwitchIcon from './platform-switch.svg'
import PCIcon from './platform-windows.svg'
import XboxIcon from './platform-xbox.svg'

const iconSize = '24px'

/**
 * direction = 'horizontal' | 'vertical'
 */
export function Platforms({
  platforms,
  direction = 'horizontal',
  enableLabels,
  showOther,
}) {
  const platformsMap = useMemo(() => {
    return {
      PC: false,
      PS4: false,
      PS5: false,
      'Series X': false,
      'Series X|S': false,
      XONE: false,
      Switch: false,
      iOS: false,
      Mac: false,
      Android: false,
      Other: [],
    }
  }, [])

  const labelsEnabled = enableLabels ?? true

  const platformsList = useMemo(() => {
    const platformsList = []

    platforms.forEach((platform) => {
      if (!platformsMap.hasOwnProperty(platform.name)) {
        platformsMap['Other'].push(platform.name)
        console.log(`Unknown platform: ${platform.name}`)
        return
      }

      platformsMap[platform.name] = true
    })

    if (platformsMap['PC']) {
      platformsList.push({ name: 'PC', component: PCIcon })
    }
    if (platformsMap['PS4'] && !platformsMap['PS5']) {
      platformsList.push({ name: 'Playstation 4', component: PlaystationIcon })
    }
    if (!platformsMap['PS4'] && platformsMap['PS5']) {
      platformsList.push({ name: 'Playstation 5', component: PlaystationIcon })
    }
    if (platformsMap['PS4'] && platformsMap['PS5']) {
      platformsList.push({ name: 'PS4/PS5', component: PlaystationIcon })
    }
    if (
      (platformsMap['Series X'] || platformsMap['Series X|S']) &&
      !platformsMap['XONE']
    ) {
      platformsList.push({ name: 'Xbox Series', component: XboxIcon })
    }
    if (!platformsMap['Series X'] && platformsMap['XONE']) {
      platformsList.push({ name: 'Xbox One', component: XboxIcon })
    }
    if (platformsMap['Series X'] && platformsMap['XONE']) {
      platformsList.push({ name: 'Xbox Series/Xbox One', component: XboxIcon })
    }
    if (platformsMap['Switch']) {
      platformsList.push({
        name: 'Nintendo Switch',
        component: SwitchIcon,
      })
    }
    if (platformsMap['iOS']) {
      platformsList.push({ name: 'iOS', component: AppleIcon })
    }
    if (platformsMap['Android']) {
      platformsList.push({ name: 'Android', component: AndroidIcon })
    }
    if (platformsMap['Mac']) {
      platformsList.push({ name: 'Mac', component: AppleIcon })
    }

    return platformsList
  }, [platformsMap, platforms])

  return (
    <>
      {direction === 'vertical' && (
        <>
          <VBox>
            {platformsList.map((platform, index) => (
              <HBox key={index} style={{ margin: '0 8px 4px 0' }}>
                <img
                  src={platform.component}
                  style={{ width: iconSize, marginRight: '8px' }}
                  alt={platform.name}
                />{' '}
                {labelsEnabled && platform.name}
              </HBox>
            ))}
          </VBox>
          {showOther && <div>{platformsMap['Other'].join(', ')}</div>}
        </>
      )}
      {direction === 'horizontal' && (
        <>
          <HBox style={{ width: '100%', flexWrap: 'wrap' }}>
            {platformsList.map((platform, index) => (
              <div
                key={index}
                style={{
                  display: 'inline-flex',
                  margin: labelsEnabled ? '0 32px 16px 0' : 0,
                }}
              >
                <img
                  src={platform.component}
                  style={{
                    width: iconSize,
                    verticalAlign: 'middle',
                    marginRight: '8px',
                  }}
                  alt={platform.name}
                />{' '}
                {labelsEnabled && (
                  <span style={{ whiteSpace: 'nowrap' }}>{platform.name}</span>
                )}
              </div>
            ))}
          </HBox>
          {showOther && <div>{platformsMap['Other'].join(', ')}</div>}
        </>
      )}
    </>
  )
}
