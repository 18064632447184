import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { usePickGame } from 'store/game/userPickGameContext'
import { useAuth } from 'store/user/authContext'

export const ConfirmDropDialog = ({ gameToDrop, setGameToDrop }) => {
  const { onDropGame } = usePickGame()
  const { user } = useAuth()

  const handleDropDialogClose = () => {
    setGameToDrop(null)
  }

  const dropGame = () => {
    onDropGame(gameToDrop, user.team.id)
    setGameToDrop(null)
  }

  return (
    <>
      <Dialog
        open={!!gameToDrop}
        onClose={handleDropDialogClose}
        aria-labelledby="drop-dialog-title"
        aria-describedby="drop-dialog-description"
      >
        <DialogTitle id="drop-dialog-title">Drop game permanently?</DialogTitle>
        <DialogContent>
          <DialogContentText id="drop-dialog-description">
            Released games cannot be picked up once you drop them. Are you sure?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDropDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={dropGame} color="primary" autoFocus>
            Drop game
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
