export const Cols = ({ set, gap, margin, children }) => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: set,
        gridGap: gap,
        margin: margin,
      }}
    >
      {children}
    </div>
  )
}
