import { Typography } from '@mui/material'
import { spacing } from 'styles/spacing'

const styles = {
  margin: `${spacing.sm} 0 !important`,
  maxWidth: '600px',
}

export const JoinIngress = () => {
  return (
    <Typography variant="body1" style={styles.ingress}>
      Critical Showdown is about predicting critics' review scores of video
      games.
    </Typography>
  )
}
