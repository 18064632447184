import { initializeApp } from 'firebase/app'
import {
  getAuth,
  onAuthStateChanged,
  sendPasswordResetEmail,
} from 'firebase/auth'
import { getAnalytics, logEvent } from 'firebase/analytics'

import firebaseConfig from './firebase-app-config'
import { browserState } from 'store/browserState'
import axios from 'axios'

const firebaseApp = initializeApp(firebaseConfig)

export const auth = getAuth(firebaseApp)

const analytics = getAnalytics()

onAuthStateChanged(auth, (user) => {
  // deal with authentication changes
  console.log('onAuthStateChanged', user)

  // This seems to happen when it shouldn't deleting the auth token and auth header for following requests?!?
  /* if (!user) {

    browserState.clearAuthToken()
    axios.defaults.headers.common = {
      Authorization: '',
    }
  } */
})

// Use local emulator in dev mode. DISABLE ON PROD
//firebase.auth().useEmulator('http://localhost:9099/')

auth.useDeviceLanguage()

export const analyticsLogEvent = (eventName) => {
  logEvent(analytics, eventName)
}

export const signOutFromFirebase = async () => {
  return auth
    .signOut()
    .then(function () {
      // Sign-out successful.
      browserState.clearAuthToken()
      axios.defaults.headers.common = {
        Authorization: '',
      }
    })
    .catch(function (error) {
      // An error happened.
      console.error('Failed to sign out', error)
    })
}

export const startResetPassword = (email) => {
  sendPasswordResetEmail(auth, email)
    .then(function () {
      // Email sent.
      console.log('Password reset email sent')
    })
    .catch(function (error) {
      // An error happened.
      console.error('Failed to send password reset email', error)
    })
}

export async function signInGoogleUserToFirebase(googleUser, signInUser) {
  console.log('Google Auth Response', googleUser)
  // Check if we are already signed-in Firebase with the correct user.
  //if (!isUserEqual(googleUser, firebaseUser)) {
  // Build Firebase credential with the Google ID token.
  var credential = auth.GoogleAuthProvider.credential(
    googleUser.getAuthResponse().id_token
  )

  // Sign in with credential from the Google user.
  return auth
    .signInWithCredential(credential)
    .then(async (response) => {
      console.log(response)
      browserState.setLoginProvider('GOOGLE')
      const idToken = await auth.currentUser.getIdToken()
      await signInUser(idToken)
    })
    .catch((error) => {
      // Handle Errors here.
      var errorCode = error.code
      var errorMessage = error.message
      // The email of the user's account used.
      var email = error.email
      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential
      console.error(errorMessage, errorCode, email, credential)
    })
  /*   } else {
    console.log('User already signed-in Firebase.')
  } */
}

/* const isUserEqual = (googleUser, firebaseUser) => {
  if (firebaseUser) {
    var providerData = firebaseUser.providerData
    for (var i = 0; i < providerData.length; i++) {
      if (
        providerData[i].providerId ===
          firebase.auth.GoogleAuthProvider.PROVIDER_ID &&
        providerData[i].uid === googleUser.getBasicProfile().getId()
      ) {
        // We don't need to reauth the Firebase connection.
        return true
      }
    }
  }
  return false
}
 */
