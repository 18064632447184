import { CSTooltip } from '../csTooltip'

export function PredictionBadgeMini({ score }) {
  return (
    <CSTooltip text={<>Your metacritic score prediction</>}>
      <div
        style={{
          borderRadius: '50%',
          width: '24px',
          height: '24px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: '1px dashed grey',
          fontSize: '13px',
        }}
      >
        {score}
      </div>
    </CSTooltip>
  )
}
