const { Collapse, Box, Alert, AlertTitle } = require('@mui/material')

export const ErrorSummary = ({ errors, label }) => {
  if (errors?.length <= 0) {
    return null
  }

  return (
    <Collapse in={Object.keys(errors).length > 0}>
      <Box p={1} />
      <Alert severity="error">
        <AlertTitle>{label}</AlertTitle>
        {Object.keys(errors).map((error, index) => (
          <div key={index}>{errors[error]}</div>
        ))}
      </Alert>
      <Box p={1} />
    </Collapse>
  )
}
