import { Box } from '@mui/material'
import { VBox } from 'Components/layout'
import { useRounds } from 'store/game/roundContext'
import { RoundSelector } from './profilePanel/roundSelector'

export const SelectRoundPage = () => {
  const { allRounds, selectedRound, setSelectedRound } = useRounds()

  return (
    <VBox>
      <h1>Select Round</h1>
      <Box p={2} />
      <RoundSelector
        rounds={allRounds}
        selectedRound={selectedRound}
        setSelectedRound={setSelectedRound}
      />
    </VBox>
  )
}
