import { Box, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { ErrorSummary } from 'Components/form/errorSummary'
import { IconButton } from 'Components/interactive'
import { ExternalLink } from 'Components/interactive/ExternalLink'
import { HBox } from 'Components/layout'
import { ChildSpacerV } from 'Components/layout/ChildSpacer'
import { HeroMessage } from 'Components/panes/heroMessage'
import { useState } from 'react'
import { apiEndpoints } from 'showdown-api/api'
import { apiPost } from 'showdown-api/rest'
import { browserState } from 'store/browserState'
import { useAuth } from 'store/user/authContext'
import { PrivacyPolicy } from '../../../Components/panes/privacyPolicy'

export const ExternalSignupView = () => {
  const { signInUser } = useAuth()

  const [formState, setFormState] = useState({
    teamName: '',
    errors: [],
    loading: false,
  })

  const handleChange = (event) => {
    const value = event.target.value
    setFormState({ ...formState, [event.target.name]: value })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setFormState({ ...formState, loading: true })

    const newUserData = {
      teamName: formState.teamName,
      inviteCode: formState.inviteCode,
    }
    await apiPost(apiEndpoints.signup_external, newUserData)
      .then(async (data) => {
        await signInUser(browserState.getAuthToken())
        browserState.clearSignupData()
        setFormState({ ...formState, loading: false })
      })
      .catch((error) => {
        if (error) {
          setFormState({
            ...formState,
            errors: error.message,
            loading: false,
          })
        }
      })
  }

  const { errors, loading } = formState
  return (
    <ChildSpacerV>
      <Typography component="h2" variant="h2">
        Welcome!
      </Typography>
      <HeroMessage padding="24px">
        Critical Showdown is now open beta. Enter <em>onemoreround</em> as your
        invite code.
      </HeroMessage>
      Join our Discord to follow the site progress.
      <HBox style={{ alignItems: 'baseline' }}>
        <ExternalLink
          url="https://discord.gg/KrrCYAx9vZ"
          label="Critical Showdown Discord"
        />
      </HBox>
      <p>
        Create your profile by giving it a name. This name will show up in
        leaderboards.
      </p>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            variant="filled"
            color="primary"
            id="inviteCode"
            label="Invite code"
            name="inviteCode"
            helperText={errors?.inviteCode ?? ''}
            error={errors?.inviteCode ? true : false}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            variant="filled"
            color="primary"
            id="teamName"
            label="Profile name (8-25 characters, A-z)"
            name="teamName"
            helperText={errors?.teamName ?? ''}
            error={errors?.teamName ? true : false}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <IconButton
        label="Create profile"
        icon="user"
        onClick={handleSubmit}
        loading={loading}
        disabled={loading || !formState.inviteCode || !formState.teamName}
        size="large"
      />
      <ErrorSummary label="Could not create profile" errors={errors} />
      <Box p={1} />
      <PrivacyPolicy />
    </ChildSpacerV>
  )
}
