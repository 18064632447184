import breakpoints from './breakpoints'

export const query = {
  mobilePortrait: `(max-width: ${breakpoints['mobilePortrait']}px)`,
  mobilePortraitToMobile: `(min-width: ${
    breakpoints['mobilePortrait'] + 1
  }px) and (max-width: ${breakpoints['mobile']}px)`,
  mobile: `(max-width: ${breakpoints['mobile']}px)`,
  mobileUp: `(min-width: ${breakpoints['mobile'] + 1}px)`,
  mobileToTablet: `(min-width: ${
    breakpoints['mobile'] + 1
  }px) and (max-width: ${breakpoints['tablet']}px)`,
  mobileToDesktopXL: `(min-width: ${
    breakpoints['mobile'] + 1
  }px) and (max-width: ${breakpoints['desktopXL']}px)`,

  mobileLandscapeDown: `(max-width: ${breakpoints['mobileLandscape']}px)`,
  mobileLandscapeUp: `(min-width: ${breakpoints['mobileLandscape'] + 1}px)`,

  tabletDown: `(max-width: ${breakpoints['tablet']}px)`,
  tablet: `(min-width: ${breakpoints['tablet'] + 1}px) and (max-width: ${
    breakpoints['desktop']
  }px)`,
  tabletUp: `(min-width: ${breakpoints['tablet'] + 1}px)`,
  tabletToDesktop: `(min-width: ${
    breakpoints['tablet'] + 1
  }px) and (max-width: ${breakpoints['desktop']}px)`,
  tabletToDesktopXL: `(min-width: ${
    breakpoints['tablet'] + 1
  }px) and (max-width: ${breakpoints['desktopXL']}px)`,

  desktopDown: `(max-width: ${breakpoints['desktop']}px)`,
  desktop: `(min-width: ${breakpoints['desktop'] + 1}px) and (max-width: ${
    breakpoints['desktopXL']
  }px)`,
  desktopUp: `(min-width: ${breakpoints['desktop'] + 1}px)`,

  desktopLDown: `(max-width: ${breakpoints['desktopL']}px)`,
  desktopLUp: `(min-width: ${breakpoints['desktopL'] + 1}px)`,

  desktopXLDown: `(max-width: ${breakpoints['desktopXL']}px)`,
  desktopXLUp: `(min-width: ${breakpoints['desktopXL'] + 1}px)`,
  desktopUltraDown: `(max-width: ${breakpoints['desktopUltra']}px)`,
  desktopUltraUp: `(min-width: ${breakpoints['desktopUltra'] + 1}px)`,
  desktopToDesktopXL: `(min-width: ${
    breakpoints['desktop'] + 1
  }px) and (max-width: ${breakpoints['desktopXL']}px)`,

  tabletToDesktopUltra: `(min-width: ${
    breakpoints['tablet'] + 1
  }px) and (max-width: ${breakpoints['desktopUltra']}px)`,

  desktopToDesktopUltra: `(min-width: ${
    breakpoints['desktop'] + 1
  }px) and (max-width: ${breakpoints['desktopUltra']}px)`,
}
