import Axios from 'axios'

export const apiGet = async (url, returnedDataOnError) => {
  if (!url) {
    console.error('No URL provided for GET request')
    return
  }

  try {
    const result = await Axios.get(url)
    return result.data
  } catch (error) {
    console.error(error)
    return returnedDataOnError
  }
}

export const apiPost = async (url, data) => {
  if (!url) {
    console.error('No URL provided for POST request')
    return
  }

  return new Promise(async (resolve, reject) => {
    try {
      const result = await Axios.post(url, data)
      resolve(result.data)
    } catch (error) {
      console.error('Full error from Axios:', error)

      const errorResponse = {
        message: error?.response?.data ?? 'No error message',
        status: error?.response?.status,
      }

      if (errorResponse.message) {
        console.error(errorResponse?.message)
      }

      reject(errorResponse)
    }
  })
}
