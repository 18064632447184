import { ChildSpacerHAll } from 'Components/layout/ChildSpacer'
import { SurpriseIcon } from './surpriseIcon'

export const GameNameBig = ({ game }) => {
  const gameName =
    game?.name?.length > 79
      ? `${game?.name?.substring(0, 79)}…`
      : game?.name ?? 'Invalid name'

  return (
    <>
      <h2>{gameName}</h2>{' '}
      <ChildSpacerHAll>
        <SurpriseIcon game={game} />
      </ChildSpacerHAll>
    </>
  )
}
