import * as React from 'react'
import { useState } from 'react'

const SignInViewContext = React.createContext()

export { SignInViewContextProvider, useSignInViewContext }

function SignInViewContextProvider(props) {
  const [openSignInDrawer, setOpenSignInDrawer] = useState(false)

  const signUpViewContext = {
    openSignInDrawer,
    setOpenSignInDrawer,
  }

  return <SignInViewContext.Provider value={signUpViewContext} {...props} />
}

function useSignInViewContext() {
  const context = React.useContext(SignInViewContext)
  if (!context) {
    throw new Error(
      `useSignInViewContext must be used within a SignInViewContextProvider`
    )
  }
  return context
}
