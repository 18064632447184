import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'

export const GameFilterMedia = ({ gameFilter, setGameFilter }) => {
  const handleChange = (event) => {
    setGameFilter({
      ...gameFilter,
      media: event.target.checked,
    })
  }

  return (
    <>
      <FormGroup>
        <h4>Media</h4>
        <FormControlLabel
          control={
            <Checkbox
              checked={gameFilter.media}
              onChange={(event) => handleChange(event)}
            />
          }
          label="Has media (video, screenshots, cover)"
        />
      </FormGroup>
    </>
  )
}
