import { Box, useMediaQuery } from '@mui/material'
import { ChildSpacerVAll } from 'Components/layout/ChildSpacer'
import { Cols } from 'Components/layout/cols'
import { JoinView } from 'Components/panes/joinView'
import { ReviewedList } from 'Components/panes/reviewedList'
import { UserPredictList } from 'Components/panes/userPredictList'
import { WhatsNew } from 'Components/panes/whatsNew'
import { WhatsNewList } from 'Components/panes/whatsNewList'
import { EndRoundMessage } from 'Components/visual/endRoundMessage'
import { MaintenanceMessage } from 'Components/visual/maintenanceMessage'
import { WinnerMessage } from 'Components/visual/winnerMessage'
import { isAfter, isBefore } from 'date-fns'
import { Link } from 'react-router-dom'
import { Breakpoint } from 'react-socks'
import { useRounds } from 'store/game/roundContext'
import { useAuth } from 'store/user/authContext'
import { query } from 'styles/mediaQueries'

function HomeHeader({ user }) {
  return (
    <>
      {' '}
      {user.isAuthenticated && <h1>Welcome back!</h1>}
      {!user.isAuthenticated && <h1>Welcome!</h1>}
      <Box p={2} />
    </>
  )
}

export const HomeContent = () => {
  const { user } = useAuth()

  const { mainRounds, selectedRound } = useRounds()
  const isMobile = useMediaQuery(query.mobile)

  if (isAfter(selectedRound.startDate, mainRounds.current.endDate)) {
    return (
      <>
        {!isMobile && <HomeHeader user={user} />}
        <p>
          Round {selectedRound.fullName} has not started yet. In the meantime
          you can check out which games will be up for grabs in this round.
        </p>
        <div>
          <Link to="/games">View games for {selectedRound.fullName}</Link>
        </div>
        <Box p={4} />
        <UserPredictList />
      </>
    )
  }

  if (isBefore(selectedRound.endDate, mainRounds.current.startDate)) {
    return (
      <>
        {!isMobile && <HomeHeader user={user} />}

        <p>Round {selectedRound.fullName} has already completed.</p>
        <div>
          <Link to="/leaderboards">
            View final results for {selectedRound.fullName}
          </Link>
        </div>
      </>
    )
  }

  return (
    <>
      {!isMobile && (
        <>
          <HomeHeader user={user} />
          <Box pt={8} />
        </>
      )}

      <ChildSpacerVAll>
        <MaintenanceMessage />
        <EndRoundMessage />
        <WinnerMessage />
        {!user.isAuthenticated && <JoinView />}

        <Breakpoint customQuery={query.tabletDown}>
          <ChildSpacerVAll>
            <UserPredictList />
            <ReviewedList />
            <WhatsNew />
          </ChildSpacerVAll>
        </Breakpoint>
        <Breakpoint customQuery={query.tabletUp}>
          <Cols set="2fr 400px" gap="96px">
            <ChildSpacerVAll>
              <UserPredictList />
              <ReviewedList />
            </ChildSpacerVAll>
            <WhatsNewList limit={2} />
          </Cols>
        </Breakpoint>
      </ChildSpacerVAll>
    </>
  )
}
