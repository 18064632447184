import axios from 'axios'
import React, { createContext, useRef, useState } from 'react'

export const CacheContext = createContext({
  getCachedItem: () => {
    return [false, null]
  },
})

export const CacheContextProvider = ({ children }) => {
  const [cache, setCache] = useState({})
  const cacheRef = useRef(cache)

  // requestId is used to track which requests have failed, preventing a loop
  const getCachedItem = (
    requestId,
    url,
    initialValue,
    observe,
    transform,
    refreshInterval,
    method
  ) => {
    //console.debug('getCachedItem requestId', requestId)
    // Use the ref to catch several fetches during one render cycle
    let cachedItem = cacheRef.current[url]
    if (
      cachedItem &&
      (!cachedItem.error || cachedItem.requestIdsFailed.includes(requestId))
    ) {
      // Check that no observed value has changed
      if (
        !observe ||
        observe.every((value, index) =>
          Object.is(value, cachedItem.observe && cachedItem.observe[index])
        )
      ) {
        return {
          ...cachedItem,
          value:
            /* (transform && transform(cachedItem.value)) ?? */ cachedItem.value,
        }
      }
      //console.debug('Observed value has changed, refetching', url)
    }

    cachedItem = {
      isLoading: true,
      error: null,
      requestIdsFailed: cachedItem ? cachedItem.requestIdsFailed : [],
      observe: observe,
      transform: transform,
      value: (cachedItem && cachedItem.value) ?? initialValue,
    }
    cacheRef.current[url] = cachedItem

    fetchData(requestId, url, method)

    if (refreshInterval) {
      setInterval(() => fetchData(requestId, url, method), refreshInterval)
    }

    return {
      ...cachedItem,
      value: (transform && transform(cachedItem.value)) ?? cachedItem.value,
    }
  }

  const setValue = (url, data) => {
    cacheRef.current[url] = {
      ...cacheRef.current[url],
      isLoading: false,
      value: data,
      error: null,
    }
    // Update state to rerender components
    setCache({ ...cacheRef.current })
  }

  const setError = (requestId, url, error) => {
    const newRequestIdsFailed = cacheRef.current[url].requestIdsFailed
    if (!newRequestIdsFailed.includes(requestId)) {
      newRequestIdsFailed.push(requestId)
    }
    cacheRef.current[url] = {
      ...cacheRef.current[url],
      isLoading: false,
      error: error,
      requestIdsFailed: newRequestIdsFailed,
    }
    setCache({ ...cacheRef.current })
  }

  const fetchData = async (requestId, url, method) => {
    console.debug('cacheContext fetchData', url)
    try {
      const cachedItem = cacheRef.current[url]

      let result
      if (method === 'get') {
        result = await axios.get(url)
      } else if (method === 'post') {
        result = await axios.post(url)
      } else {
        result = await axios.get(url)
      }

      if (cachedItem && cachedItem.transform) {
        setValue(url, cachedItem.transform(result.data))
      } else if (result.data.data) {
        setValue(url, result.data.data)
      } else {
        setValue(url, result.data)
      }
    } catch (error) {
      setError(requestId, url, error)
    }
  }

  const cacheContext = {
    getCachedItem,
  }

  return (
    <CacheContext.Provider value={cacheContext}>
      {children}
    </CacheContext.Provider>
  )
}
