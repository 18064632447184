import { useMemo } from 'react'
import { useGames } from 'store/game/gameContext'
import { useGameFilter } from 'store/game/gameFilterContext'
import { useRounds } from 'store/game/roundContext'
import { useAuth } from 'store/user/authContext'
import { findRealFromIds } from 'util/list'
import { UserPanelMiniView } from './userPanelMiniView'

export const UserPanelMini = ({ closeDrawer }) => {
  const { user } = useAuth()
  const { gameLimit } = useGameFilter()

  const { availableGames, selectedGames, selectedGamesOutsideRound } =
    useGames()
  const { mainRounds, selectedRound } = useRounds()
  const currentRound = mainRounds.current
  const isCurrentRound = selectedRound.id === currentRound.id

  // Convert to real game data
  const gameList = useMemo(() => {
    const gameListIds = selectedGames?.map((game) => {
      return game.id
    })
    return findRealFromIds(availableGames, gameListIds)
  }, [availableGames, selectedGames])

  const gamesOutsideList = useMemo(() => {
    let gamesOutsideList = []
    if (isCurrentRound) {
      gamesOutsideList = selectedGamesOutsideRound
    }
    const gamesOutsideListIds = gamesOutsideList?.map((game) => {
      return game.id
    })
    return findRealFromIds(availableGames, gamesOutsideListIds)
  }, [availableGames, selectedGamesOutsideRound, isCurrentRound])

  return (
    <>
      <UserPanelMiniView
        user={user}
        gameList={gameList}
        gamesOutsideList={gamesOutsideList}
        gameLimit={gameLimit}
        closeDrawer={closeDrawer}
      />
    </>
  )
}
