import { ChildSpacerV } from 'Components/layout/ChildSpacer'
import { HLine } from 'Components/visual/HLine'
import { Text } from 'Components/visual/text'
import { Breakpoint } from 'react-socks'
import { query } from 'styles/mediaQueries'

const AboutText = () => {
  return (
    <Text>
      {' '}
      <p>
        Critical Showdown is a passion project made by two brothers. We’re doing
        this in our free time because it’s a thing we enjoy ourselves and a
        service we felt was missing.
      </p>{' '}
      We are a developer (Benny) and UX Designer (Henrik) who’s living and
      working in different cities and this is a great opportunity for us to get
      together and make something using our different skill sets.{' '}
      <p>
        We are certainly enjoying making Critical Showdown and hope you are
        enjoying your time here as well.
      </p>{' '}
      Drop us a line if you want to give us feedback or just want to say hello!
    </Text>
  )
}

export const AboutUs = () => {
  return (
    <ChildSpacerV>
      <HLine icon="signature" label="About us" />
      <Breakpoint customQuery={query.mobile}>
        <AboutText />
      </Breakpoint>
      <Breakpoint customQuery={query.mobileUp}>
        <div style={{ columnCount: 2, columnGap: 16 }}>
          <AboutText />
        </div>
      </Breakpoint>
    </ChildSpacerV>
  )
}
