import { useTheme } from '@emotion/react'
import { Box, CircularProgress, Tab, Tabs } from '@mui/material'
import { LeaderboardsSortButtonGroup } from 'Components/game/leaderboardsSortButtonGroup'
import { HBox, VBox } from 'Components/layout'
import { ChildSpacerV } from 'Components/layout/ChildSpacer'
import { BestScoredList } from 'Components/panes/bestScoredList'
import { FantasyLeaderboardList } from 'Components/panes/fantasyLeaderboardsList'
import { PredictorLeaderboardList } from 'Components/panes/predictorLeaderboardsList'
import React from 'react'
import { Breakpoint } from 'react-socks'
import SwipeableViews from 'react-swipeable-views'
import { useRounds } from 'store/game/roundContext'
import { useLeaderboards } from 'store/leaderboard/leaderboardContext'
import { query } from 'styles/mediaQueries'
import { FantasyLeaderboardChart } from './fantasyLeaderboardChart'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

export const RenderLeaderboards = () => {
  const { isLoadingLeaderboards, leaderboards, leaderboardsSortMode } =
    useLeaderboards()

  const theme = useTheme()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = (index) => {
    setValue(index)
  }

  return (
    <>
      <VBox>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Picks" {...a11yProps(0)} />
          <Tab label="Predictions" {...a11yProps(1)} />
        </Tabs>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <>
              <Box p={4} />
              <HBox align="center-right">
                <LeaderboardsSortButtonGroup />
              </HBox>
              <Box p={2} />
              <LeaderboardPanel
                isLoading={isLoadingLeaderboards}
                leaderboards={leaderboards}
                selectedLeaderboardType={'fantasy'}
                leaderboardsSortMode={leaderboardsSortMode}
              />
              <Box p={5} />
              <BestScoredList />
            </>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <>
              <Box p={5} />
              <LeaderboardPanel
                isLoading={isLoadingLeaderboards}
                leaderboards={leaderboards}
                selectedLeaderboardType={'predictor'}
                leaderboardsSortMode={leaderboardsSortMode}
              />
            </>
          </TabPanel>
        </SwipeableViews>
      </VBox>
      <Breakpoint customQuery={query.tabletUp}>
        <Box p={2}></Box>
        <FantasyLeaderboardChart />
      </Breakpoint>
      <Box p={2}></Box>
    </>
  )
}

function LeaderboardPanel({
  isLoading,
  leaderboards,
  selectedLeaderboardType,
  leaderboardsSortMode,
}) {
  const { selectedRound } = useRounds()

  if (isLoading) {
    return (
      <VBox align="center">
        <Box p={2} />
        <CircularProgress />
        <Box p={1} />
        Loading leaderboards
      </VBox>
    )
  }

  const leaderboardRound = leaderboards[selectedRound.id]

  if (!leaderboardRound) {
    return <p>No leaderboard yet. Check back later.</p>
  }
  const selectedLeaderboard = leaderboardRound[selectedLeaderboardType]
  if (!selectedLeaderboard) {
    return <p>No leaderboard yet. Check back later.</p>
  }
  const leaderboardList =
    leaderboards[selectedRound.id][selectedLeaderboardType] ?? []

  return (
    <>
      {selectedLeaderboardType === 'fantasy' && (
        <div>
          <Box p={1} />
          <FantasyLeaderboardList
            label={null}
            leaderboardList={leaderboardList}
            selectedRound={selectedRound ?? null}
            leaderboardsSortMode={leaderboardsSortMode}
          />
        </div>
      )}
      {selectedLeaderboardType === 'predictor' && (
        <div>
          <ChildSpacerV>
            <PredictorLeaderboardList
              label={null}
              leaderboardList={leaderboardList}
              selectedRound={selectedRound ?? null}
              leaderboardsSortMode={leaderboardsSortMode}
            />
          </ChildSpacerV>
        </div>
      )}
    </>
  )
}
