import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { useLeaderboards } from 'store/leaderboard/leaderboardContext'

export const LeaderboardsSortButtonGroup = () => {
  const {
    leaderboardsSortMode,
    setLeaderboardsSortMode,
    LEADERBOARD_SORT_OPTIONS,
  } = useLeaderboards()

  return (
    <>
      <ToggleButtonGroup
        exclusive
        color="primary"
        value={leaderboardsSortMode}
        onChange={(e, value) => {
          setLeaderboardsSortMode(value)
        }}
      >
        <ToggleButton value={LEADERBOARD_SORT_OPTIONS.score}>
          <span
            className="material-symbols-outlined"
            style={{ marginLeft: '-4px' }}
          >
            expand_more
          </span>
          <span style={{ fontSize: '13px' }}>
            {LEADERBOARD_SORT_OPTIONS.score.label}
          </span>
        </ToggleButton>
        <ToggleButton value={LEADERBOARD_SORT_OPTIONS.scoreAvg}>
          {' '}
          <span
            className="material-symbols-outlined"
            style={{ marginLeft: '-4px' }}
          >
            expand_more
          </span>
          <span style={{ fontSize: '13px' }}>
            {LEADERBOARD_SORT_OPTIONS.scoreAvg.label}
          </span>
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  )
}
