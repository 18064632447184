import { CircularProgress } from '@mui/material'
import { VBox } from 'Components/layout'
import { apiEndpoints } from 'showdown-api/api'
import { useMemo, useState } from 'react'
import { useGames } from 'store/game/gameContext'
import {
  buildPredictionGrade,
  calcPredictionPoints,
  isGameReleased,
} from 'util/gameLogic'
import { findRealFromIds } from 'util/list'
import { sortByProperty, sortByReleasedAndName } from 'util/sort'
import useCachedData from 'util/useCachedData'
import { TeamPredictionsListView } from './teamPredictionsListView'
import { PREDICTIONS_SORT_OPTIONS } from './predictionsSortButton'
import { useRounds } from 'store/game/roundContext'

export const InspectTeamPredictionsView = ({ userId, selectedRound }) => {
  const [isLoadingTeam, team] = useCachedData(
    apiEndpoints.team + '/' + userId, // TODO: bug loading null team when closing. move this out to leaderboardlist?
    null,
    [userId]
  )

  const { isGameReleasedInRound } = useRounds()

  const { isLoadingAvailableGames, availableGames } = useGames()

  const [showNotReleased, setShowNotReleased] = useState(false)
  const [showNoPoints, setShowNoPoints] = useState(false)

  const [predictionsSortMode, setPredictionsSortMode] = useState(
    PREDICTIONS_SORT_OPTIONS.release
  )

  const toggleSortMode = () => {
    setPredictionsSortMode(
      predictionsSortMode.id === PREDICTIONS_SORT_OPTIONS.release.id
        ? PREDICTIONS_SORT_OPTIONS.points
        : PREDICTIONS_SORT_OPTIONS.release
    )
  }

  const inspectedUser = useMemo(() => {
    if (!team) {
      return null
    }

    const realGames = findRealFromIds(
      availableGames,
      Object.keys(team.predictions)
    )

    const realGamesWithinRound = realGames.filter((game) =>
      isGameReleasedInRound(game, selectedRound)
    )

    const sortedGames = realGamesWithinRound.sort((a, b) =>
      sortByReleasedAndName(a, b, 'DESC')
    )

    let totalPoints = 0

    const predictions = sortedGames.map((game) => {
      let predictionScore = team.predictions[game.id]

      if (typeof predictionScore === 'string') {
        predictionScore = parseInt(predictionScore)
      }

      const diff = Math.abs(game.metacritic - predictionScore)
      const grade = buildPredictionGrade(diff)
      const points = calcPredictionPoints(diff)

      totalPoints += points

      return {
        game,
        predictionResult: { predictionScore, diff, grade, points },
      }
    })

    const predictionsFiltered = predictions.filter((prediction) => {
      if (!isGameReleased(prediction.game) && !showNotReleased) {
        return false
      }

      if (
        isGameReleased(prediction.game) &&
        !prediction.predictionResult.points &&
        !showNoPoints
      ) {
        return false
      }

      return true
    })

    let predictionsSorted = predictionsFiltered
    if (predictionsSortMode.id === PREDICTIONS_SORT_OPTIONS.points.id) {
      predictionsSorted = predictionsFiltered.sort((a, b) =>
        sortByProperty(a.predictionResult, b.predictionResult, 'points', 'DESC')
      )
    }

    const teamWithPredictions = {
      ...team,
      totalPoints,
      predictions: predictionsSorted,
    }

    return { team: teamWithPredictions, userId: team.userId }
  }, [
    team,
    availableGames,
    showNotReleased,
    showNoPoints,
    selectedRound,
    predictionsSortMode,
    isGameReleasedInRound,
  ])

  return (
    <>
      {(isLoadingTeam || isLoadingAvailableGames) && (
        <VBox align="center">
          <CircularProgress />
        </VBox>
      )}
      {!isLoadingTeam && inspectedUser && (
        <TeamPredictionsListView
          inspectedUser={inspectedUser}
          showNotReleased={showNotReleased}
          setShowNotReleased={setShowNotReleased}
          showNoPoints={showNoPoints}
          setShowNoPoints={setShowNoPoints}
          predictionsSortMode={predictionsSortMode}
          toggleSortMode={toggleSortMode}
        />
      )}
    </>
  )
}
