import { Box } from '@mui/material'

export const VBox = ({ align, style, className, flex, flexType, children }) => {
  const alignmentMapping = {
    'center-right': {
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    'center-left': {
      alignItems: 'flex-start',
      justifyContent: 'center',
    },
    center: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    right: {
      alignItems: 'flex-end',
      justifyContent: 'flex-start',
    },
    'right-center': {
      alignItems: 'flex-end',
      justifyContent: 'center',
    },
    left: {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
  }

  const alignment = alignmentMapping[align]

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex={flex ?? flexType === 'hug' ? '0 1 auto' : 1}
      style={style}
      className={className}
      {...alignment}
    >
      {children}
    </Box>
  )
}
