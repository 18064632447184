import { primitiveColors } from './primitiveColors'

export const semanticMedia = {
  /// from design system
  accentTheme: primitiveColors.pColorTheme300,

  contrastLow: primitiveColors.pColorGray400,
  contrastMedium: primitiveColors.pColorGray200,
  contrastHigh: primitiveColors.pColorGray50,

  onDisabled: primitiveColors.pColorGray300,
  onLight: primitiveColors.pColorGray950,
}
