import { Button, CircularProgress } from '@mui/material'
import { colors } from 'styles/colors'
export const RoundCardButton = ({
  label,
  icon,
  onClick,
  saving,
  disabled,
  color,
  style,
}) => {
  return (
    <Button
      disabled={!!disabled}
      onClick={onClick}
      style={{
        color: color ? color : colors.white,
        ...style,
      }}
      sx={{
        borderRadius: '20px',
        height: '40px',
        minWidth: 0,
        ml: '-2px',
      }}
    >
      {saving && (
        <CircularProgress disableShrink size={16} style={{ marginRight: 4 }} />
      )}
      {!saving && <span className="material-symbols-outlined">{icon}</span>}{' '}
      {label}
    </Button>
  )
}
