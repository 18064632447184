import { Box } from '@mui/material'
import {
  Link,
  ScrollRestoration,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { useRounds } from 'store/game/roundContext'
import { InspectTeamPredictionsView } from '../sidebar/inspectTeamPredictionsView'

export const TeamPredictionsView = () => {
  const { teamUserId } = useParams()
  const { selectedRound } = useRounds()
  const navigate = useNavigate()

  return (
    <>
      <ScrollRestoration />
      <Link
        to={'..'}
        onClick={(e) => {
          e.preventDefault()
          navigate(-1)
        }}
      >
        <span
          className="material-symbols-outlined"
          style={{ verticalAlign: 'text-top' }}
        >
          arrow_back
        </span>{' '}
        Back
      </Link>
      <Box p={1}></Box>
      <InspectTeamPredictionsView
        userId={teamUserId}
        selectedRound={selectedRound}
      />
    </>
  )
}
