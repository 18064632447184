export const gameProfileService = {
  calcPoints: function (game, gameProfile, isLoadingGameProfile) {
    if (!game) {
      return -1000
    }

    let profilePoints = -1
    if (!isLoadingGameProfile && gameProfile?.cover) {
      profilePoints = 0
      if (game.cover) profilePoints += gameProfile.cover
      if (game.summary) profilePoints += gameProfile.summary
      if (game.storyline) profilePoints += gameProfile.storyline
      if (game.screenshots) profilePoints += gameProfile.screenshots
      if (game.videos) profilePoints += gameProfile.videos

      if (game.game_modes) {
        game.game_modes.forEach((item) => {
          profilePoints += gameProfile.game_modes.hasOwnProperty(item.name)
            ? gameProfile.game_modes[item.name]
            : 0
        })
      } else {
        profilePoints += gameProfile.game_modes.hasOwnProperty('None')
          ? gameProfile.game_modes['None']
          : 0
      }

      if (game.themes) {
        game.themes.forEach((item) => {
          profilePoints += gameProfile.themes.hasOwnProperty(item.name)
            ? gameProfile.themes[item.name]
            : 0
        })
      } else {
        profilePoints += gameProfile.themes['None']
      }

      if (game.genres) {
        game.genres.forEach((item) => {
          profilePoints += gameProfile.genres.hasOwnProperty(item.name)
            ? gameProfile.genres[item.name]
            : 0
        })
      } else {
        profilePoints += profilePoints += gameProfile.genres.hasOwnProperty(
          'None'
        )
          ? gameProfile.genres['None']
          : 0
      }

      if (game.player_perspectives) {
        game.player_perspectives.forEach((item) => {
          profilePoints += gameProfile.player_perspectives.hasOwnProperty(
            item.name
          )
            ? gameProfile.player_perspectives[item.name]
            : 0
        })
      } else {
        profilePoints += gameProfile.player_perspectives['None']
      }

      if (gameProfile?.totalPlatforms.hasOwnProperty(game?.platforms?.length)) {
        profilePoints += gameProfile.totalPlatforms[game?.platforms?.length]
      } else if (
        game?.platforms?.length &&
        gameProfile?.totalPlatforms?.length > 0
      ) {
        profilePoints +=
          gameProfile.totalPlatforms[gameProfile.totalPlatforms.length - 1]
      }

      if (
        gameProfile?.totalGameModes.hasOwnProperty(game?.game_modes?.length)
      ) {
        profilePoints += gameProfile.totalGameModes[game?.game_modes?.length]
      } else if (
        game?.game_modes?.length &&
        gameProfile?.totalGameModes?.length > 0
      ) {
        profilePoints +=
          gameProfile.totalGameModes[gameProfile.totalGameModes.length - 1]
      }

      if (
        gameProfile?.totalPlayerPerspectives.hasOwnProperty(
          game?.player_perspectives?.length
        )
      ) {
        profilePoints +=
          gameProfile.totalPlayerPerspectives[game?.player_perspectives?.length]
      } else if (
        game?.player_perspectives?.length &&
        gameProfile?.totalPlayerPerspectives?.length > 0
      ) {
        profilePoints +=
          gameProfile.totalPlayerPerspectives[
            gameProfile.totalPlayerPerspectives.length - 1
          ]
      }

      if (gameProfile?.totalThemes.hasOwnProperty(game?.themes?.length)) {
        profilePoints += gameProfile.totalThemes[game?.themes?.length]
      } else if (game?.themes?.length && gameProfile?.totalThemes?.length > 0) {
        profilePoints +=
          gameProfile.totalThemes[gameProfile.totalThemes.length - 1]
      }

      if (gameProfile?.totalGenres.hasOwnProperty(game?.genres?.length)) {
        profilePoints += gameProfile.totalGenres[game?.genres?.length]
      } else if (game?.genres?.length && gameProfile?.totalGenres?.length > 0) {
        profilePoints +=
          gameProfile.totalGenres[gameProfile.totalGenres.length - 1]
      }

      if (gameProfile?.totalCategory.hasOwnProperty(game?.category)) {
        profilePoints += gameProfile.totalCategory[game?.category]
      } else if (game?.category && gameProfile?.totalCategory?.length > 0) {
        profilePoints +=
          gameProfile.totalCategory[gameProfile.totalCategory.length - 1]
      }

      if (
        gameProfile?.totalInvolvedCompanies.hasOwnProperty(
          game?.involved_companies?.length
        )
      ) {
        profilePoints +=
          gameProfile.totalInvolvedCompanies[game?.involved_companies?.length]
      } else if (
        game?.involved_companies?.length &&
        gameProfile?.totalInvolvedCompanies?.length > 0
      ) {
        profilePoints +=
          gameProfile.totalInvolvedCompanies[
            gameProfile.totalInvolvedCompanies.length - 1
          ]
      }

      if (game?.involved_companies) {
        game.involved_companies.forEach((item) => {
          if (item.developer) {
            profilePoints += gameProfile.developers.hasOwnProperty(
              item.company.name
            )
              ? gameProfile.developers[item.company.name]
              : 0
          }
          if (item.publisher) {
            profilePoints += gameProfile.publishers.hasOwnProperty(
              item.company.name
            )
              ? gameProfile.publishers[item.company.name]
              : 0
          }
          if (item.supporting) {
            profilePoints += gameProfile.supporting.hasOwnProperty(
              item.company.name
            )
              ? gameProfile.supporting[item.company.name]
              : 0
          }
          if (item.porting) {
            profilePoints += gameProfile.porting.hasOwnProperty(
              item.company.name
            )
              ? gameProfile.porting[item.company.name]
              : 0
          }
        })
      }
    }

    if (isNaN(profilePoints)) {
      console.log('Invalid profile points. Check out why')
    }

    return profilePoints
  },
}
