import { Box } from '@mui/material'
import { RoundSwitch } from 'Components/panes/roundSwitch'
import { Logo } from 'Components/visual'
import { colors } from 'styles/colors'
import { MainMenu } from '../header/mainMenu'
import { ProfilePanelMiniView } from './profilePanelMiniView'

export const ProfilePanel = () => {
  return (
    <aside
      style={{
        backgroundColor: colors.appBackground,
      }}
    >
      <div
        id="sidebar-content"
        style={{
          margin: '48px',
          width: '400px',
          /*           right: 0,
          top: 0,
 */
        }}
      >
        <Logo />
        <Box pt={5} />
        <RoundSwitch />
        <Box pt={5} />
        <MainMenu />
        <Box pt={8} />
        <ProfilePanelMiniView />
      </div>
    </aside>
  )
}
