import { analyticsLogEvent } from 'Components/firebase'
import produce from 'immer'
import React, { useMemo } from 'react'
import { useAuth } from 'store/user/authContext'
import { usePredictions } from './predictionsContext'
import { useGames } from './gameContext'
import {
  getGamePredictionScore,
  hasPredictionForGame,
  isGameLocked,
  isGameReleased,
  SCORE_LIKELY_THRESHOLD,
} from 'util/gameLogic'
import { addDays, isAfter } from 'date-fns'
import { useRounds } from './roundContext'
import { buildTodayZero } from 'util/date'
import { sortByReleasedAndName } from 'util/sort'

const UserPredictGameContext = React.createContext()

function PredictGameProvider({ ...props }) {
  const { children } = props
  const { user, setUser } = useAuth()
  const { roundGamePredictions, updateRoundGamePrediction } = usePredictions()
  const { availableGames } = useGames()

  const { selectedRound, isGameReleasedInRound } = useRounds()

  const gamesPossibleToPredict = useMemo(() => {
    const possibleGames = availableGames
      .filter(
        (game) =>
          game.profilePoints >= SCORE_LIKELY_THRESHOLD &&
          !isGameLocked(game) &&
          !isGameReleased(game) &&
          isGameReleasedInRound(game, {
            startDate: isAfter(selectedRound.startDate, buildTodayZero())
              ? buildTodayZero()
              : selectedRound.startDate,
            endDate: addDays(buildTodayZero(), 14),
          })
      )
      .sort((a, b) => sortByReleasedAndName(a, b))

    return possibleGames
  }, [availableGames, selectedRound, isGameReleasedInRound])

  const gamesToPredict = useMemo(() => {
    const gamesToPredict = gamesPossibleToPredict.filter(
      (game) => !hasPredictionForGame(user, game)
    )

    return gamesToPredict
  }, [gamesPossibleToPredict, user])

  const gamesUserPredicted = useMemo(() => {
    const predictedGames = availableGames.filter(
      (game) =>
        hasPredictionForGame(user, game) &&
        isGameReleasedInRound(game, {
          startDate: isAfter(selectedRound.startDate, buildTodayZero())
            ? buildTodayZero()
            : selectedRound.startDate,
          endDate: selectedRound.endDate,
        })
    )

    const userPredictionsForGames = predictedGames.map((game) => {
      const prediction = getGamePredictionScore(user, game)
      return {
        gameId: game.id,
        score: prediction,
      }
    })

    return userPredictionsForGames
  }, [availableGames, selectedRound, user, isGameReleasedInRound])

  const onPredictGame = (newPrediction) => {
    const predictedGameId = newPrediction.gameId

    // Update game with predictions
    const gamePredictionsList = roundGamePredictions.filter(
      (game) => game.id === predictedGameId
    )

    let updatedGamePrediction = null
    if (gamePredictionsList.length > 0) {
      const gamePredictions = gamePredictionsList[0]

      if (newPrediction.score === 0) {
        updatedGamePrediction = produce(gamePredictions, (draft) => {
          if (!gamePredictions.predictions) {
            gamePredictions.predictions = { teams: [] }
          }

          const teams = gamePredictions.predictions.teams
          const teamsWOTeam = teams.filter(
            (team) => team.id !== newPrediction.teamId
          )
          draft.predictions.teams = teamsWOTeam
        })
      } else {
        updatedGamePrediction = produce(gamePredictions, (draft) => {
          if (!gamePredictions.predictions) {
            gamePredictions.predictions = { teams: [] }
          }

          const teams = gamePredictions.predictions.teams
          const teamsWOTeam = teams.filter(
            (team) => team.id !== newPrediction.teamId
          )
          draft.predictions.teams = [
            ...teamsWOTeam,
            {
              id: newPrediction.teamId,
              name: user.team.name,
              score: newPrediction.score,
              wins: user.team.wins,
            },
          ]
        })
      }

      // Update roundGamePrediction with new prediction
      updateRoundGamePrediction(updatedGamePrediction)
    }

    // Update user with prediction saved to backend
    const updatedUser = produce(user, (draft) => {
      if (!draft.team.predictions) {
        draft.team.predictions = {}
      }

      draft.team.predictions[predictedGameId] =
        newPrediction.score === 0 ? undefined : newPrediction.score
    })

    setUser(updatedUser)

    analyticsLogEvent('manage_games', { name: 'predict_game' })
  }

  const predictGameContext = {
    onPredictGame,
    gamesPossibleToPredict,
    gamesToPredict,
    gamesUserPredicted,
  }

  return (
    <UserPredictGameContext.Provider value={predictGameContext}>
      {children}
    </UserPredictGameContext.Provider>
  )
}

function usePredictGame() {
  const context = React.useContext(UserPredictGameContext)
  if (!context) {
    throw new Error(`usePickGame must be used within a PickGameProvider`)
  }
  return context
}

export { PredictGameProvider, usePredictGame }
