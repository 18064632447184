import * as React from 'react'
import { apiEndpoints } from 'showdown-api/api'
import useCrudData from 'util/useCrudData'
import { useRounds } from './roundContext'

const PredictionsContext = React.createContext()

function PredictionsProvider({ children }) {
  const { selectedRound } = useRounds()

  // Load predictions for games in the selected round
  const [
    isLoadingRoundGamePredictions,
    roundGamePredictions,
    createRoundGamePrediction,
    readRoundGamePrediction,
    updateRoundGamePrediction,
    deleteRoundGamePrediction,
    setRoundGamePrediction,
  ] = useCrudData(
    apiEndpoints.predictions_by_round + '?roundId=' + selectedRound.id,
    [], // initial value
    null, // observe
    null // transform
  )

  const predictionsContext = {
    isLoadingRoundGamePredictions,
    roundGamePredictions,
    createRoundGamePrediction,
    readRoundGamePrediction,
    updateRoundGamePrediction,
    deleteRoundGamePrediction,
    setRoundGamePrediction,
  }

  return (
    <PredictionsContext.Provider value={predictionsContext}>
      {children}
    </PredictionsContext.Provider>
  )
}

function usePredictions() {
  const context = React.useContext(PredictionsContext)
  if (!context) {
    throw new Error(`usePredictions must be used within a PredictionsProvider`)
  }
  return context
}

export { PredictionsProvider, usePredictions }
